@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
@import url(https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css);
/* font - face */
/* @import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css'); */
/* 기본 값 초기화 */
  *{
    margin:0 auto;
    padding:0 auto;
  }

  *:focus { 
    outline:none; 
  }  
  

  h1,h2,h3,h4,h5,h6 {
    font-size:100% 
  }

/* 좌우 border */
.solid {
  border:0.5px 
  solid #eeeeee;
  border-top-style: none;
  border-bottom-style: none;
}

/* 공통 section css 작업 부분 */
/* Element{
  height:500px !important;
} */
.section-contents{
  width:64%;
  height:100%;
}

.indicate{
  width:1%;
  height:54%;
  padding-right:40px;
  padding-top:23%;
  float:right !important;

}

.indicateOn{
  width:12px;
  height:12px;
  float:left;
}

.indicateOff{
  width:12px;
  height:12px;
  float:left;
}


.contents-left{
  width:50%;
  height:100%;
  float:left;
  
}
.contents-text-no-title {
  width:100%;
  height:520px;
  padding-top:220px;
  word-break: keep-all;
  
}

.contents-text-no-title h3{
  font-family:'NanumSquare', sans-serif;
  font-size: 17px;
  line-height: 1.76;
  font-weight: 400;
  color:#30343b;
  word-break: break-all;
  /* width:600px; */
}

.contents-text-right{
  width:100%;
  padding-top:230px;
  word-break: keep-all;
  
}

.contents-text-right h2{
  font-family:'NanumSquare', sans-serif;
  font-size: 28px;
  line-height: 1;  
  color:#30343b;
  font-weight:600;
}

.contents-text-right h3{
  font-family:'NanumSquare', sans-serif;
  font-size: 17px;
  line-height: 1.76;
  font-weight: 400;
  color:#30343b;
  position: absolute;
  padding-top:30px;
  word-break: break-all;
  /* width:600px; */
}

.contents-text{
  /* width:85%;
  height:520px; */
  padding-top:120px;
  word-break: keep-all;
  
}

.contents-text h1{
  font-family:'NanumSquare', sans-serif;
  font-size:50px;
  line-height: 1;
  color:#30343b;
  font-weight:900;

}
.contents-text h2{
  font-family:'NanumSquare', sans-serif;
  font-size:32px;
  Line-height:1.38;
  font-weight: 600;
  color:#000000;

}
.contents-text h3{
  font-family:'NanumSquare', sans-serif;
  font-weight: 400;
  font-size:16px;
  line-height: 1.63;
  opacity: 0.7;
  padding-top:40px;
  color:#000000;
  width: 92%;
  float: left;
  font-weight: 300;

}

.red{
  color:#ff5543;
}

.white{
  color:#ffffff;
}
 
/* .contents-contents{
  text-align:left;
  color:#000000;
  padding-top:150px;

} */

.contents-right{
  width:50%;
  height:100%;
  float:left;
  text-align: left;
  vertical-align: middle;
  display:table-cell;
  padding: 0 auto;
  margin: 0 auto;
}


.appdownload{
  display: none !important;
}

.size{
  width:100%;
  height:100%;  
}

.link{
  cursor: pointer;
  padding:40px 35px 70px 0px;
}

img { 
  border:0 
}


.terms{
  max-width: 600px;
  margin:0 auto;
  padding: 20px;
}

.terms h2{
  margin-bottom: 20px;
  font-size: 1.375rem;
  line-height: 1;
  font-weight:600;
}

.terms h3{
  margin-top:20px;
  margin-bottom:10px;
  line-height:1;
  font-size:15px;
  font-weight:600;
}

.terms h4{
  font-weight:500;
  font-size:14px;
  float:left;
}

.terms p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: .8125rem;
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: .8125rem;
}

.ol{
  list-style-type: none;
}

.ul{
  list-style-type: circle
}

.table{
  text-align:left;
  border-bottom:  0.5px solid #ddd;
}

th, td{
  text-align: left;
  border: 0.5px solid #ddd ;
  padding: 7px 10px;
  
}

table {
  width: 100%;
  text-align: center;
  border: 0.5px solid #ddd;
  border-bottom:  0.5px solid #ddd;
  border-collapse: collapse;
}

.Media-play-icon {
  width: 22px;
  height: 22px;
  float:left;
  padding:14px 0px 14px 14.5px;
}

 .container-fluid{
  width: 100%;
  align-content: center;
}  

.news-img-base{
  display: block;
}

.team-line-top-desktop{
  display: block;
  width: 26px;
  height: 5px;
  background-color: #22262d;
  float: left;
}


/* display: none*/
.h3-mobile ,.contents-mobile, .mobile-sidebar, .mobile-slidebar, .menu-bar, .app-tablet
, .moditt-img-tablet,.news-img-tablet,.news-link-tablet,
.comment2,.section-mobile,.section1-mobile, .news-link-desktop-tablet,.news-img-mobile,.facebook-moditt-mobile, .footer-tablet, .app-mobile-section {
  display:none;
}



.wrap{    
    width:100%;
    height:100%;
    padding-bottom: 84px;
}

.all_header{
    width:100%;
    height:720px;
    background: url(../../static/media/moditt-spot-bg@3x.bf981b59.jpg);
    background-size:cover;
    background-position:top;
    background: cover;
    z-index :2;
}

.header{
    width:100%;
    height: 130px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    color: #ffffff;
    z-index: 0;
    position: fixed;
    -webkit-transition: top 0.2s ease-in-out;
    transition: top 0.2s ease-in-out;
        /* text-align: center;
    position: fixed !important;
    display:flex;   
    */
}

.header.shown{
    display: none;
    width:100%;
    height: 130px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    color: #ffffff;
    z-index: 0;
    position: fixed;
    -webkit-transition: top 0.2s ease-in-out;
    transition: top 0.2s ease-in-out;
        /* text-align: center;
    position: fixed !important;
    display:flex;
   
    */
}



.header-fixed{  
    visibility: hidden;  
    width:100%;
    height: 100px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    background-color: #ffffff;
    z-index :1;
    position: fixed;
    border-bottom: .5px solid #eee;
    /* text-align: center;
    position: fixed !important;
    display:flex;
    z-index :99999; */
    
}


.header-fixed.shown{  
    visibility: visible;   
    width:100%;
    height: 100px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    background-color: #ffffff;
    z-index :1;
    position: fixed;
    border-bottom: .5px solid #eee;
    /* text-align: center;
    position: fixed !important;
    display:flex;
    z-index :99999; */
    
}

.menu-fixed{
    height:100%;
    margin:0 auto;
    font-family: 'NanumSquare', sans-serif;
    font-weight:700; 
    float:right;
    text-align:center;
}

.menu-fixed li{
    float:left;
    list-style:none;
    line-height: 100%;
    padding: 40px 35px 40px 0px;
}
.moditt_logo_fixed{    
    float:left;
    /* width:200px; */
    height:40px;
    margin:0 auto;
    padding:0 auto;
    text-align: left;
    padding: 30px 0px 30px 50px;
    cursor: pointer;
}

.moditt_logo{    
    float:left;
    width:200px;
    height:50px;
    margin:0 auto;
    padding:0 auto;
    text-align: left;
    padding: 40px 0px 40px 50px;
    cursor: pointer;
}


.menu{
    height:100%;
    margin:0 auto;
    font-family: 'NanumSquare', sans-serif;
    font-weight:700; 
    float:right;
    text-align:center;
}

.menu li{
    float:left;
    list-style:none;
    line-height: 100%;
    padding: 40px 35px 70px 0px;
}

.header-nav{
    float:right;
    font-size:16px;
    color: #000000;
    margin:0 auto;
    padding: 0.5%;
    font-family: 'NanumSquare', sans-serif;
    font-weight:700; 
    padding-right:2%;
}
.header_title {
     position:relative; 
     width:752px;
     height:75px; 
     left:0;
     right:0;
     top:45%;
     bottom:10%;
     z-index: 99999;
    } 

p{
    height:100%;
    font-family:  'NanumSquare', sans-serif;
    font-weight:900;
    float:center;
    text-align:center;
    font-size: 75px;
    line-height: 1;
    color:#ffffff;
}
  /* Device = Tablets, Ipads , Screen = 768px to 1023px */
@media (min-width: 768px) and (max-width: 1367px) {  
    .wrap{    
        width:100%;
        height:100%;
        padding-bottom: 64px !important;
    }
    .munse_app {
        display: none;
    }
    
    .solid {
        border:1px 
        solid #dddddd;
        border-top-style: none;
        border-bottom-style: none;
        border-left-style:none;
        border-right-style:none;
      }

      .mobile-slidebar{
        display: block;
    }

    .mobile-slidebar.shown{
        display: none;
    }


    .size{
        width:100% !important; 
      }

    .section-contents{
        width:90% !important;
        height:100%;
        align-content: center;
    }

    .moditt_logo{         
        float:left;
        width:120px !important;
        height:30px !important;
        margin:0 auto;
        padding:0 auto;
        text-align: center;
        padding: 10px 0px 10px 20px !important;
    }

    .moditt_logo.shown{ 
        display: none;
        float:left;
        width:120px !important;
        height:30px !important;
        margin:0 auto;
        padding:0 auto;
        text-align: center;
        padding: 10px 0px 10px 20px !important;
    }

    .moditt_logo_fixed{    
        float:left;
        width:120px !important;
        height:30px !important;
        margin:0 auto;
        padding:0 auto;
        text-align: center;
        padding: 10px 0px 10px 20px !important;
        z-index: 999999 !important;
        position: absolute;
    }
    .header-fixed.shown{
        height:49px !important;
    }

    .nav-menu{
        width:95%;
        height:49px;  
        display: none;    
    }

    .menu-bar{
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:12.5px;
    }
    .navbar{
        width:100% !important;
        height:100%;
        margin:0 auto;
        font-family: 'NanumSquare', sans-serif;
        font-weight:700; 
        float:right;
        text-align:center;
    }

    .all_header{
        height:360px;
    }

    .header_title{
        top:10%;
    }

    .change_life_style{
        width: 688px;
        height: 40px;
        font-family: 'NanumSquare', sans-serif;
        font-size: 40px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding-top:120px;
    }
    /* .menu{
        height:100%;
        margin:0 auto;
        font-family: 'Noto Serif KR', sans-serif;
        font-weight:700; 
        float:right;
        text-align:center;
        font-size:14px;
    } */
/*     
    .menu li{
        float:left;
        list-style:none;
        line-height: 100%;
        padding: 25px 30px 25px 0px;
    } */
    
    .header-nav{
        float:right;
        font-size:16px;
        color: #000000;
        margin:0 auto;
        padding: 0.5%;
        font-family: 'NanumSquare', sans-serif;
        font-weight:700; 
        padding-right:2%;
    }

.section1{
    width:100% !important;
    height:417px !important;
    /* background-repeat: no-repeat !important;
    background-position:center !important;      */
    /* background-position : center !important;
    background-repeat : no-repeat !important; */
    }
    
.contents{
    width:100% !important;
    height:90% !important;
}

.contents-left{
    width:100% !important;
    height:25% !important;
    float:left;
    text-align: center !important;
    vertical-align: middle;
    display:table-cell;
    padding: 0 auto;
    margin: 0 auto;
  }

.contents-text{
    padding-top:75px;
    float:left;
}
.contents-right{
    width: 100%!important;
    height:65% !important;
    float:left ;
    /* text-align: center !important; */
    /* vertical-align: middle; */
    display:table-cell;
    padding: 0 auto;
    margin: 0 auto;
  }
.contents-text-right{
    padding-top:40px;
}

.contents-text-right h2{
    font-family: 'NanumSquare', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;    
    letter-spacing: normal;
    color: #30343b;
}

.contents-text-right h3{
    padding-top:40px;
    font-family: 'NanumSquare', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87 !important;
    letter-spacing: normal;
    color: #30343b;
}

.contents-text-no-title h3{
    font-size:15px !important;
    line-height: 1.87 !important;
}
.contents-contents{
    text-align:center !important; 
    color:#000000;
    padding-top:0px;
  
  }

.contents-text{
    text-align:center !important;
}

.contents-text-top{
    width:100% !important;
    height:45% !important;
    padding-top:100px !important;
    margin: 0px 0px !important;
    word-break: keep-all;
  }

.top-app, .indicate{
    display:none;
}
  

.sectionimg{
    width:inherit;
    max-width:100%;
    width:470.8px;
    height:480px;
    padding:50px 100px !important;

}

.top-text{
    font-size:32px;
    Line-height:1.38;
    color:#ffffff;
    text-align: center;

}

.top-title{
    font-family: 'NanumSquare', sans-serif;
    font-weight:600 !important; 
  font-size:32px;
  Line-height:1.38;
  color:#ffffff;
  text-align:center;
}

.top-contetns{
    font-family: 'NanumSquare', sans-serif;
    font-weight:400 !important; 
    font-size:14px !important;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:30px;
    color:#ffffff;
    text-align:center;
}
.indicate-width{
    width:100%;
    float:left;
    text-align: center;
}
.indicate{
    width:12px;
    height:12px;
    text-align: center;
}

/* section2*/
.section2{
    width:100% !important;
    height:211.7px !important;
    max-width:1367px !important;
}
.sectionimg1{
    width:inherit;
    max-width:100%;
    width: 470.8px;
    height:480px;
    padding:0px 0px;
}
.contents-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-bottom: 75px;
   
  
  }
  .contents-text h2{
    font-family:'NanumSquare', sans-serif;
    font-weight: 900;
    font-weight:600 !important; 
    font-size:28px;
    Line-height:1.38;
    font-weight: 300;
    color:#000000;
    text-align: center;
  
  }
  .contents-text h3{
    font-family:'NanumSquare', sans-serif;
    font-weight:600 !important; 
    font-size:14px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:50px;
    color:#000000;
    text-align: center;
    width:100%;
  }
  .section3{
    width:100%;
    height:408px !important;
    background-color:#fafafa;
}

.contents-text-no-title{
    padding-top:40px;
    font-family: 'NanumSquare', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #30343b;
    height:100%;
}   

.section4{
    width:100%;
    height:1048px !important;
}
.app-tablet{
    display:block;
}
.app-tablet-section{
    width:100%;
    height:25%;
    float:left;
    padding-top:75px;   
}

.app-icon{
    width:80px !important;
    height:80px !important;
}

.contents-app{
    padding:0px !important;
    width:  0px !important;
}

.app-description {
    padding-left:120px;
    width:83% !important;
}
.app-description h1{
    font-family: 'NanumSquare', sans-serif;
    font-size: 20px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:0px !important;
    float: left;
}

.google-apple h1{
    font-size:15px !important;
    float:left;
    padding: 16.5px 0px 0px 12px !important;
    width:120px !important;
}
.web {
    padding-top:0 !important;
}

.app-description h2{
    opacity: 0.5;
    font-family:'NanumSquare', sans-serif;
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87 !important;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:40px !important;
    word-break: break-all !important;
}


.google-apple{
    padding-top:0 !important;
    cursor: pointer;
}


.app{
    padding-top:40px !important;
    padding-bottom:0px !important;
    width:180px !important;
    padding-right:15px;
}
.go-to-web h3{
    padding-top: 0px !important;
    padding-left:20px;
    cursor: pointer;
}

.section5{
    width:100%;
    height:678px !important;
}

.w-contents-left{
    width:100% !important;
    height: 48% !important;
}

.w-contents-text h1{
    font-size: 34px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
}

.w-contents-text h2{
    font-size: 28px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:91px !important;
}

.w-contents-text{
    padding-top:75px !important;
}

.w-contents-text .team-line {
    margin-top:75px !important;
    width:20.8px !important;
    height:4px !important;
}

.w-contents-right{
    width:100% !important;
    height:50% !important;
}

.w-contents-text-right{
    padding-top:75px !important;
}

.w-contents-text-right h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 25px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;  
}

.w-contents-text-right h3{
    font-family: 'NanumSquare', sans-serif;
  font-size: 15px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.87;
  letter-spacing: normal;
  color: #ffffff;
  word-break: break-all;
}



.sectionimg4{
    width:inherit;
    max-width:100%;
    width: 470.8px !important;
    height:480px !important;
    padding:0px 0px !important; 

}
.section6 {
    height:3246px !important;
}
.section6_top{
    width:100% !important;
    height:2013px !important;
}




/* .section6-tablet{
    position:absolute;
    float: center;
    align-content: center
} */

.three-img{
    width: 100% !important;
    float: left;
    padding-top: 75px !important;
    height: 471px !important;
    padding-bottom:90px;
}

.moditt-work{
    width:100% !important;
    height:471px !important;
}

.moditt-work-text{
    float: left;
    padding-top:40px !important;
    width:100% !important;
}

.moditt-work-text h1{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-top:0 !important;
}

.moditt-work-text h2{
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #30343b;
    padding-top: 20px !important;
}

.moditt-img-tablet{
    width:100% !important;
    height: 350px !important;
    display: block;
}

.team-line-top{
    width:20.8px !important;
    height: 4px !important;
}

.team-line-top-desktop{
    display: block;
    width:20.8px !important;
    height: 4px !important;
    background-color: #22262d;
    float: left;
}


.section6_bottom{
    height:1233px !important;
}

.w-contents-left2{
    height:127px !important;
    width:100%;
}

.box{
    padding-top:60px !important;
    width: 100% !important;
    height:157px !important;
}

.box2{
    padding-top:60px !important;
    width: 100% !important;
    height:157px !important;
    text-align: left !important;
    padding-right:0px !important;
}
.ham {
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    padding-top:66px !important;
    color: #ffffff; 
    text-align: left !important;
  }


.ham-h2{
    font-family:'NanumSquare', sans-serif;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 1.87 !important; 
    padding-top:19px;
    opacity: 0.5;
    color:#ffffff;
    text-align: left !important;
  }

.box h1{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff; 
}

.box h2{
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #ffffff;
}

.interview-right{
    float: left !important;
}

.comment{
    width:100px !important;
    height:45.8px !important;
}
.contents-top{
    width:100%;
    height:62%;
}

.contents-mus{
    width: 57%;
    height:100%;
}
.contents-mus-text{
    text-align: center;
    padding-top:80px;
}

h4{
    font-family: 'NanumSquare', sans-serif;
        font-weight:500 !important; 
    font-size: 16px !important;
    line-height: 1.44;
    text-align: center;
    color: #000000;
    padding: 20px 20px;
}

.appicon{
    width:100px;
    height:100px;
    
}

.section7{
    height: 438px !important;
}

.b-contents-text h1{
    font-size: 28px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
}

.b-contents-text-no-title{
    padding-top:75px !important;
    width:100% !important;
    font-weight:900 !important;
}

.b-contents-text-no-title h3{
    width:100% !important;
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87 !important;
    letter-spacing: normal;
    color: #30343b;
}

.join{
    margin-top:40px !important;
}

.section8{
    height: 1537px !important;
}

.news{
    width:688px !important;
    height: 471px !important;
}

.news-img{
    width:100% !important;
    padding-top:0px !important;
    height: 676px !important;
}

.news-text{
    width:100% !important;
}

.news-text h1{
    float:left;
    font-size: 20px !important;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-top:40px !important;
}
.news-link-tablet{
    display: block;
    float:left;
} 


.news-text h3{
    padding-top:44px !important;
    padding-left:20px !important;
}

.news-web{
    padding-top:44px !important;
}

.news-text h2{
    float:left;
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #30343b;
    width: 100%;
}

.section9{
    height:140px !important;
}
.facebook-moditt{
    width:240px !important
}

.facebook-moditt h1 {
    font-size:20px !important;
    font-weight: 600 !important;
}

.facebook{
    margin-left:267px !important;
}

.facebook h1{
    font-size: 15px !important;
}
.contents-app-down{
    width:57%;
    height:100%;
    text-align:center;
    margin:0 auto;
    padding:0 auto;
}

.contents-app-text{
    text-align: center;
    
}
.appstore-bottom, .googleplay-bottom{    
    display: none;
}

.google{
    height:50px !important;

}

.google-maum{
    width: 375px;
    height: 100%;
    float: left;
    border-radius: 0.5em;
    border: 1px solid #ffffff;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;

}

.Media-play-icon{
    padding:14px 0px 0px 14.5px;
}

.appdownload{
    text-align:center;
    width:200px;
    height:46px;
    padding-top:30px;
    display:block !important;
    cursor: pointer;
}

.contents-bottom{
    width:100%;
    height:38%;
    text-align:center;
}
.footer{
    width:100%;
    height:280px !important;
}

.w-facebook-moditt{
    width:100% !important;
    height: 50% !important;
}

.w-facebook-moditt h4{
    padding-top:40px !important;
    float:left;
}

.w-facebook-moditt h5{
    padding-top: 10px !important;
}
.footer-contents{

    width:85% !important;
    height:100%;
}

.footer-text{
    width:100%;
}

.footer-tablet{
    display: block;
}

.container-fluid{
    width: 100% !important;
    align-content: center;
  }  


.header{
    width: 100%;
    height: 130px;
    font-family: 'NanumSquare', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    z-index: 0;
    position: fixed;
    -webkit-transition: top 0.2s ease-in-out;
    transition: top 0.2s ease-in-out;
}

.header.shown{
    display: none;
    width: 100%;
    height: 130px;
    font-family:'NanumSquare', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    z-index: 0;
    position: fixed;
    -webkit-transition: top 0.2s ease-in-out;
    transition: top 0.2s ease-in-out;
}


  .header-fixed{  
    visibility: hidden;  
    width:100%;
    height: 100px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    background-color: #ffffff;
    z-index :1;
    position: fixed;
    border-bottom: .5px solid #eee;
    /* text-align: center;
    position: fixed !important;
    display:flex;
    z-index :99999; */
    
}


.header-fixed.shown{  
    visibility: visible;   
    width:100%;
    height: 100px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    background-color: #ffffff;
    z-index :1;
    position: fixed;
    border-bottom: .5px solid #eee;
    /* text-align: center;
    position: fixed !important;
    display:flex;
    z-index :99999; */
    
}

.news-img-tablet{
    display: block;

}

.comment2{
    display: block;
    width: 100px !important;
    height: 45.8px !important;
    float: left;

}


/* display : none */
.indicate, .mobile-copyright,.menu, .menu-fixed,.desktop, .moditt-img, .news-link-desktop
,.footer-desktop, .team-line-top-career, .news-img-base, .section1-mobile,.section-mobile,.facebook-moditt-mobile,.comment21{
    display:none;
}
}
  /* Device = mobile , Screen = 414px to 767px */
  @media (min-width: 0px) and (max-width: 767px) {  
    .wrap{    
        width:100%;
        height:100%;
        padding-bottom: 64px !important;
    }
    
    .solid {
        border:1px 
        solid #dddddd;
        border-top-style: none;
        border-bottom-style: none;
        border-left-style:none;
        border-right-style:none;
      }



    .size{
        width:100% !important; 
      }

    .section-contents{
        width:280px !important;
        height:100%;
    }

    .moditt_logo{    
        float:left;
        width:120px !important;
        height:30px !important;
        margin:0 auto;
        padding:0 auto;
        text-align: center;
        padding: 10px 0px 10px 20px !important;
    }

    .moditt_logo_fixed{
        width:120px !important;
        height:30px !important;
        float:left;
        padding: 10px 0px 10px 20px !important;
        z-index: 999999 !important;
        position: absolute;
        
    }
    .nav-menu{
        width:95%;
        height:49px; 
        display:none;      
    }

    .menu-fixed{
        display: none;
    }

    .menu-bar{
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:12.5px;
        cursor:pointer;
    }
    .navbar{
        width:100% !important;
        height:100%;
        margin:0 auto;
        font-family:'NanumSquare', sans-serif;
        text-align:center;
    }

    .mobile-slidebar{
        display: block;
    }


    .all_header{
        height:320px !important;
    }

    .header_title{
        top:30%;
        width:280px !important;
    }

    .header{
        height:50px;
    }

    .header-fixed{  
        visibility: hidden;  
        width:100%;
        height: 50px;
        font-family: 'NanumSquare', sans-serif;
        font-size:20px;
        background-color: #ffffff;
        z-index :1;
        position: fixed;
        border-bottom: .5px solid #eee;
        /* text-align: center;
        position: fixed !important;
        display:flex;
        z-index :99999; */
        
    }
    
    
    .header-fixed.shown{  
        visibility: visible;   
        width:100%;
        height: 50px;
        font-family: 'NanumSquare', sans-serif;
        font-size:20px;
        background-color: #ffffff;
        z-index :1;
        position: fixed;
        border-bottom: .5px solid #eee;
        /* text-align: center;
        position: fixed !important;
        display:flex;
        z-index :99999; */
        
    }
    .change_life_style{
        width: 280px;
        height: 40px;
        font-family: 'NanumSquare', sans-serif;
        font-size: 30px;
        
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding-left:0px !important;
        padding-top:50px;
    }
    /* .menu{
        height:100%;
        margin:0 auto;
        font-family: 'Noto Serif KR', sans-serif;
        font-weight:700; 
        float:right;
        text-align:center;
        font-size:14px;
    } */
/*     
    .menu li{
        float:left;
        list-style:none;
        line-height: 100%;
        padding: 25px 30px 25px 0px;
    } */
    
    .header-nav{
        float:right;
        font-size:16px;
        color: #000000;
        margin:0 auto;
        padding: 0.5%;
        font-family: 'NanumSquare', sans-serif;
        padding-right:2%;
    }

.section1{
    width:100% !important;
    height:315px !important;
    /* background-repeat: no-repeat !important;
    background-position:center !important;      */
    /* background-position : center !important;
    background-repeat : no-repeat !important; */
    }
    
.contents{
    width:100% !important;
    height:90% !important;
}

.contents-left{
    width:100% !important;
    height:25px !important;
    float:left;
    text-align: center !important;
    vertical-align: middle;
    display:table-cell;
    padding: 0 auto;
    margin: 0 auto;
  }

.contents-text{
    padding-top:50px !important;
    float:left;
}
.contents-right{
    width:100% !important;
    height:65% !important;
    float:left ;
    /* text-align: center !important; */
    /* vertical-align: middle; */
    display:table-cell;
    padding: 0 auto;
    margin: 0 auto;
  }
.contents-text-right{
    padding-top:20px;
}

.contents-text-right h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-top:50px;
}

.contents-text-right h3{
    padding-top:20px;
    font-family:'NanumSquare', sans-serif;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #30343b;
    width:280px;
    z-index: 1;
    position: static;
    position: initial;
}

.section1-mobile{
    display: block;
}
.contents-contents{
    text-align:center !important; 
    color:#000000;
    padding-top:0px;
  
  }

.contents-text{
    text-align:center !important;
}

.contents-text-top{
    width:100% !important;
    height:45% !important;
    padding-top:100px !important;
    margin: 0px 0px !important;
    word-break: keep-all;
  }

.top-app, .indicate{
    display:none;
}
  

.sectionimg{
    width:inherit;
    max-width:100%;
    width:470.8px;
    height:480px;
    padding:50px 100px !important;

}

.top-text{
    font-size:32px;
    Line-height:1.38;
    color:#ffffff;
    text-align: center;

}

.top-title{
    font-family: 'NanumSquare', sans-serif;
  font-size:32px;
  Line-height:1.38;
  color:#ffffff;
  text-align:center;
}

.top-contetns{
    font-family:'NanumSquare', sans-serif;
    font-size:14px !important;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:30px;
    color:#ffffff;
    text-align:center;
}
.indicate-width{
    width:100%;
    float:left;
    text-align: center;
}
.indicate{
    width:12px;
    height:12px;
    text-align: center;
}

/* section2*/
.section2{
    width:100% !important;
    height:88.3px !important;
    
}
.sectionimg1{
    width:inherit;
    max-width:100%;
    width: 470.8px;
    height:480px;
    padding:0px 0px;
}
.contents-text h1{
    font-size: 25px !important; 
    line-height: 1;
    padding-bottom:50px;

  
  }
  .contents-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:28px;
    Line-height:1.38;
    font-weight: 300;
    color:#000000;
    text-align: center;
  
  }
  .contents-text h3{
    font-family:'NanumSquare', sans-serif;
    font-size:14px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:50px;
    color:#000000;
    text-align: center;
    width:100%;
  }
  .section3{
    width:100%;
    height:353px !important;
    background-color:#fafafa;
}

.contents-text-no-title{
    padding-top:20px;
    font-family:'NanumSquare', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #30343b;
    height:100%;
}   

.contents-text-no-title h3{
    padding-top:50px;
    font-family:'NanumSquare', sans-serif;
    font-size: 12px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #30343b;
}   

.section4{
    width:100%;
    height:1064px !important;
}
.munse_app {
    display: block;
}
.app-mobile{
    display:block;
}
.app-mobile-section{
    width:100%;
    height:25%;
    float:left;
    padding-top:50px;   
    display: block;
}

.app-icon{
    width:60px !important;
    height:60px !important;
}

.contents-app{
    padding:0px !important;
    width:  0px !important;
}

.app-description {
    padding-left:85px;
}
.app-description h1{
    font-family: 'NanumSquare', sans-serif;
    font-size: 17px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:0px !important;
    float: left;
}

.google-maum{
    width: 280px;
    height: 37.5px;
    float: left;
    border-radius: 0.5em;
    border: 1px solid #ffffff;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;

}

.google-maum .Media-play-icon{
    padding:10.2px 0px 10.2px 82px;
}

.google-apple h1{
    font-size:12px !important;
    padding:12.9px 9px 12.8px 31px !important;
}

.Media-play-icon{
    width:17px !important;
    height:17px;
    padding: 10.2px 0px 10.2px 9px;
}

.google, .apple{
    width:135px !important;
    height:37.5px !important;
}



.web {
    padding-top:20px !important;
}

.app-description h2{
    opacity: 0.5;
    font-family:'NanumSquare', sans-serif;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:10px !important;
    float:left;
}


.google-apple{
    padding-top:0 !important;
}


.app{
    padding-top:32px !important;
    padding-bottom:0px !important;
    width:135px !important;
    
}

.app-2{
    padding-top:32px !important;
    padding-bottom:0px !important;
    width:135px !important;
    padding-left:10px;
    float: right;
    
}
.go-to-web h3{
    padding-top: 20px !important;
    padding-left:0px;
}

.section5{
    width:100%;
    height:540px !important;
}

.w-contents-left{
    width:100% !important;
    height: 206px !important;
}

.w-contents-text h1{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2 !important;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:60px !important;
}

.w-contents-text h2{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:59px !important;
}

.w-contents-text{
    padding-top:0px !important;
}

.w-contents-text .team-line {
    margin-top:50px !important;
    width: 16px !important;
    height: 3px !important;
}

.w-contents-right{
    width:100% !important;
    height:50% !important;
}

.w-contents-text-right{
    padding-top:50px !important;
    word-break:break-all !important;
}

.w-contents-text-right h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 20px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25 !important;
    letter-spacing: normal;
    color: #ffffff;
    width: 278px;
}

.w-contents-text-right h3{
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 22px !important;
    color: #ffffff;
    float: left;
    opacity: 0.5;
    padding-top: 20px !important;
}



.sectionimg4{
    width:inherit;
    max-width:100%;
    width: 470.8px !important;
    height:480px !important;
    padding:0px 0px !important; 

}
.section6 {
    height:1922px !important;
}
.section6_top{
    width:100% !important;
    height:1036px !important;
}




/* .section6-tablet{
    position:absolute;
    float: center;
    align-content: center
} */

.three-img{
    width: 100% !important;
    float: left;
    padding-top: 50px !important;
    height: 282px !important;
    padding-bottom:-10px;
}

.moditt-work{
    width: 280px !important;
    height: 192px !important;
}

.moditt-work-text{
    float: left;
    padding-top:20px !important;
    width:100% !important;
}

.moditt-work-text h1{
    font-size: 16px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #30343b;
    padding-top:0 !important;
}

.moditt-work-text h2{
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83 !important;
    letter-spacing: normal;
    color: #30343b;
    padding-top: 10px !important;
}

.moditt-img-tablet{
    width:100% !important;
    height: 146px !important;
    display: block;
}

.team-line-top{
    width:16px !important;
    height: 3px !important;
    margin-top:50px;
}

.team-line-top-career{
    display: block;
    width:16px !important;
    height: 3px !important;
    margin-top:0px;
    background-color: #22262d;
    float: left;
}

.section6_bottom{
    height:886px !important;
}

.w-contents-left2{
    height:127px !important;
    width:100%;
}

.box{
    padding-top:0px !important;
    width: 100% !important;
    height:157px !important;
    padding-bottom: 40px;
}

.box2{
    width:100% !important;
    float: left !important;
    height: 157px !important;
    text-align: left !important;
    padding-top:0px !important;
    padding-bottom: 40px;
}
.ham {
    font-family:'NanumSquare', sans-serif;
    font-size:16px !important;
    line-height: 1;
    padding-top:56.7px !important;
    color:#ffffff;
    text-align: left !important;
  }


.ham-h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 1.83 !important; 
    padding-top:10px !important;
    opacity: 0.5;
    color:#ffffff;
    text-align: left !important;
  }

.box h1{
    font-size: 16px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff; 
    padding-top:20.3px !important;
}

.box h2{
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83 !important; 
    letter-spacing: normal;
    color: #ffffff;
    padding-top:10px !important;
}

.interview-right{
    float: left !important;
}

.comment{
    width:80px !important;
    height:36.7px !important;
}

.comment2{
    width:80px !important;
    height:36.7px !important;
    float: left;
    
}
.contents-top{
    width:100%;
    height:62%;
}

.contents-mus{
    width: 57%;
    height:100%;
}
.contents-mus-text{
    text-align: center;
    padding-top:80px;
}

h4{
    font-family: 'NanumSquare', sans-serif;
    font-size: 16px !important;
    line-height: 1.44;
    text-align: center;
    color: #000000;
    padding: 20px 20px;
}

.appicon{
    width:100px;
    height:100px;
    
}

.section7{
    height: 349px !important;
}

.b-contents-text h1{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2 !important;
    letter-spacing: normal;
    padding-top:6px !important;
    font-weight: bold;
}

.b-contents-text-no-title{
    padding-top:20px !important;
    word-break: break-all !important;
    width:280px !important;
}

.b-contents-text-no-title h3{
    width:100% !important;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83 !important;
    letter-spacing: normal;
    color: #30343b;
    padding-top:60px;
}

.join{
    margin-top:30px !important;
    width:280px !important;
    height:37.50px !important;
}

.join h1{
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1 !important;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width:80px !important;
    padding: 12.5px 100px 13px 100px !important;
}

.section8{
    height: 927px !important;
}

.news{
    width:280px !important;
    height: 192px !important;
}

.news-img{
    width:100% !important;
    padding-top:0px !important;
    height: 389px !important;
}

.news-text{
    width:100% !important;
}

.news-text h1{
    float:left;
    font-size: 16px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-top:20px !important;
}
.news-link-tablet{
    display: block;
    float:left;
} 


.news-text h3{
    padding-top:20px !important;
    padding-left:0px !important;
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 1.17 !important;
    color: #30343b;
    padding-top: 20px;
}

.news-web{
    padding-top:20px !important;
}

.news-text h2{
    float:left;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83 !important;
    letter-spacing: normal;
    color: #30343b;
    padding-top: 10px !important;
}

.section9{
    height:134px !important;
}
.facebook-moditt{
    width:280px !important
}

.facebook-moditt h1 {
    font-size:16px !important;
    padding:30px 20px 0px 40px !important;
}


.facebook-moditt-mobile h1 {
    font-size:16px !important;
    padding:12.8px 94.5px 12.8px 94.5px !important;
}

.facebook{
    margin-left:0px !important;
    margin-top:20px !important;
    width:280px !important;
    height:37.5px !important;   
}

.facebook h1{
    font-size: 12px !important;
    width:91px !important;
}
.contents-app-down{
    width:57%;
    height:100%;
    text-align:center;
    margin:0 auto;
    padding:0 auto;
}

.contents-app-text{
    text-align: center;
    
}
.appstore-bottom, .googleplay-bottom{    
    display: none;
}

.appdownload{
    text-align:center;
    width:200px;
    height:46px;
    padding-top:30px;
    display:block !important;
    cursor: pointer;
}

.contents-bottom{
    width:100%;
    height:38%;
    text-align:center;
}
.footer{
    width:100%;
    height:246px !important;
}

.w-facebook-moditt{
    width:100% !important;
    height: 50% !important;
}

.w-facebook-moditt h1
{
    padding: 40px 0px 20px 0px !important;
    font-family: 'NanumSquare', sans-serif;
    font-size: 16px !important;
    line-height: 1;
    color: #ffffff; 
}

.w-facebook-moditt h2{
    padding-top: 20px !important;
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 1;
    opacity: 0.7 !important;
    color: #ffffff;
}
.w-facebook-moditt h3{
    padding-top: 10px;
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 1;
    opacity: 0.7;
    color: #ffffff;
}

.w-facebook-moditt h4{
    padding-top:40px !important;
    float:left;
}

.w-facebook-moditt h5{
    padding-top: 10px !important;
    font-size:12px !important;
}
.footer-contents{

    width:85% !important;
    height:100%;
}

.footer-text{
    width:100%;
}

.footer-tablet{
    display: block;
}

.container-fluid{
    width: 100% !important;
    align-content: center;
  }  

.facebook-moditt-mobile {
    display: block;
}
  
.news-img-mobile{
    display: block;
}

.comment2{
    display: block;
}


/* display : none */
.indicate, .mobile-copyright,.menu, .desktop, .moditt-img, .news-link-desktop
,.footer-desktop,.news-img-tablet,.app-tablet, .section1-base,.facebook-moditt-base, 
.comment21,.news-img-base, .team-line-top-desktop{
    display:none;
}
}
@media (min-width: 0px) and (max-width: 1367px) {   

      .bm-overlay{
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        opacity: 1;
        -webkit-transition: opacity 0.3s ease 0s;
        transition: opacity 0.3s ease 0s;
    }
    
      .menubtn{
        /* background:none;
        position:absolute;
        top:0;
        left:0;
        line-height:45px;      
        color:#999;
        cursor:pointer;
        width:14px;
        height:14px;
        text-align:center;
        padding:12px 20px 12px 20px; */
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:13px 12.5px 0px 0px;
        cursor:pointer;
        z-index: 999999;
      }

      /* .menubtn.shown{
        display:none;
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:20px 12.5px 0px 0px;
        cursor:pointer;
        z-index: 999999;
      } */
     
      .menubarblack{
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:20px 12.5px 0px 0px;
        cursor:pointer;
        z-index: 999999;
      }

      .modittlogo{
        width:120px;
        height:30px;
        float:left; 
        left:0;
        padding-top:10px;
      }

      

      .menulist-top{
        height:49px;
        padding:0px 20px 0px 20px;
      }
      .cross{
        background:none;
        position:absolute;
        top:0;
        right:0;  
        color:#999;
        cursor:pointer;
        width:24px;
        height:24px;
        text-align:center;
        float:left;
        padding:13px 12.5px 0px 583px;
        z-index: 999999;
      }

      .menulist{
        background: #ffffff;
        /* padding: 0px 30px 0px 30px !important; */
        font-size: 16px;
        /* width:100%; */
        /* height:206px !important; */
        text-align:left;
        font-family: 'NanumSquare', sans-serif;
        font-weight:700; 
        height:269px;
        z-index: 999999;
        position: fixed;
        width:100%;
        
    }

    .menulist-fixed-top{
      height:49px;
      padding:0px 20px 0px 20px;
    }
    .menulist-fixed{
      background: #ffffff;
      /* padding: 0px 30px 0px 30px !important; */
      font-size: 16px;
      /* width:100%; */
      /* height:206px !important; */
      text-align:left;
      font-family: 'NanumSquare', sans-serif;
      font-weight:700; 
      height:269px;
      z-index: 999999;
      position: fixed;
      width:100%;
      
  }
  .menulist-fixed ul {
          margin: 0; 
          padding: 0; 
          height:179px;
          padding-left:20px;
          padding-top:40px;
          list-style-type: none; 
          list-style-image: none;
          
          z-index: 999999;
        }
      .menulist-fixed li {
          display: block;   
          padding:0px 0px 25px 0px;
          cursor: pointer;
        }

      .menulist-fixed ul li a { 
          text-decoration:none;  
          margin: 0px; 
          color:#000000;
        }
      .menulist-fixed ul li a:hover {  
          color: #000000; 
          text-decoration:none;
        }
      .menulist-fixed a{
          text-decoration:none; 
          color:#000000;
        }
      .menulist-fixed a:hover{
          text-decoration:none; 
          color:#000000;
        }
      .about{
        font-family:'NanumSquare', sans-serif;   
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #30343b;
        float:left;
        
      }

      .services, .team, .menu-news{
        font-family:'NanumSquare', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #30343b;
        float:left;
        height:41px;
        
      }


    .desktop-menu{
      width:100%;
    }
    .menulist ul {
          margin: 0; 
          padding: 0; 
          height:179px;
          padding-left:20px;
          padding-top:40px;
          list-style-type: none; 
          list-style-image: none;
        }
      .menulist li {
          display: block;   
          padding:0px 0px 25px 0px;
          cursor: pointer;
        }

      .menulist ul li a { 
          text-decoration:none;  
          margin: 0px; 
          color:#000000;
        }
      .menulist ul li a:hover {  
          color: #000000; 
          text-decoration:none;
        }
      .menulist a{
          text-decoration:none; 
          color:#000000;
        }
      .menulist a:hover{
          text-decoration:none; 
          color:#000000;
        }
      .about{
        font-family: 'NanumSquare', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #30343b;
        float:left;
        
      }

      .services, .team, .menu-news{
        font-family: 'NanumSquare', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #30343b;
        float:left;
        height:41px;
        
      }


   
      .pay{
        padding-top:25.5px;
        padding-bottom:40px;
      }
      
      .glyphicon-home{
        color:white; 
        font-size:1.5em; 
        margin-top:5px; 
        margin:0 auto;
      }

      header{
        display:inline-block; 
        font-size:12px;}

      p {
        padding-left:0px;
      }
      a{color:#336699;}


      .menulist hr{
        width:90%;
        float:center;
      }

      /*display : none */
      .menu{
          display:none;
      }

 
    }

.section1{
    width:100%;
    height:588px;
    background-color: #ffffff;
     display: block;   
     position: static;
     align-content: center;
    /* position: static; */
}

.section1_bgimg{
    background-size: 1440px 100%;
}

.contents{
    width:100%;
    height:90%;
}
.contents-text-top{
    width:570px;
    height:520px;
    margin: 50px 100px;
    word-break: keep-all;
  }

.top-app{
    width:100%;
    height:25%;
    padding-top:60px;
}

.appstore, .googleplay{
    width:50%;
    float:left;
    width:186px;
    height:46px;
    padding-right:10px;
    cursor: pointer;
}

.sectionimg{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 100px;
    

}

.top-text{
    font-family: 'NanumSquare', sans-serif;
    font-weight:300; 
    font-size:32px;
    Line-height:1.38;
    color:#ffffff;

}

.top-title{
    font-family: 'NanumSquare', sans-serif;
    font-weight:600; 
  font-size:32px;
  Line-height:1.38;
  color:#ffffff;
}

.top-contetns{
    font-family: 'NanumSquare', sans-serif;
    font-weight:400; 
    font-size:16px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:40px;
    color:#ffffff;
}


.appdownload{
    display:none;
}

.section2{
    width:100%;
    height:360px;
    background-color:#ffffff;
    background: url(../../static/media/moditt-message@3x.dd58b19d.png);
    background-size:cover;
    background-position:top;
    background: cover;
    max-width:1920px;
}


.sectionimg1{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 0px;
}



  /* Device = Desktop = 1024px to 1439px */
@media (min-width: 1368px) and (max-width: 1830px) { 
/* font - face */
/* 기본 값 초기화 */
  *{
    margin:0 auto;
    padding:0 auto;
  }

  *:focus { 
    outline:none; 
  }  

  h1,h2,h3,h4,h5,h6 {
    font-size:100% 
  }

/* 좌우 border */
.solid {
  border:0.5px 
  solid #eeeeee;
  border-top-style: none;
  border-bottom-style: none;
}

/* 공통 section css 작업 부분 */
/* Element{
  height:500px !important;
} */
.section-contents{
  width:84%;
  height:100%;
}

.indicate{
  width:1%;
  height:54%;
  padding-right:40px;
  padding-top:23%;
  float:right !important;

}

.indicateOn{
  width:12px;
  height:12px;
  float:left;
}

.indicateOff{
  width:12px;
  height:12px;
  float:left;
}


.contents-left{
  width:50%;
  height:100%;
  float:left;
  
}
.contents-text-no-title {
  width:100%;
  height:520px;
  padding-top:220px;
  word-break: keep-all;
  
}
.munse_app {
  display: none;
}
.contents-text-no-title h3{
  font-family:'NanumSquare', sans-serif;
  font-size: 17px;
  line-height: 1.76;
  font-weight: 400;
  color:#30343b;
  /* width:600px; */
}

.contents-text-right{
  width:100%;
  padding-top:230px;
  word-break: keep-all;
  
}

.contents-text-right h2{
  font-family:'NanumSquare', sans-serif;
  font-size: 28px;
  line-height: 1;  
  color:#30343b
}

.contents-text-right h3{
  font-family:'NanumSquare', sans-serif;
  font-size: 17px;
  line-height: 1.76;
  font-weight: 400;
  color:#30343b;
  position: absolute;
  padding-top:30px;
  /* width:600px; */
}

.contents-text{
  /* width:85%;
  height:520px; */
  padding-top:120px;
  word-break: keep-all;
  
}

.contents-text h1{
  font-family:'NanumSquare', sans-serif;
  font-size:50px;
  line-height: 1;
  font-weight: 900;
  color:#30343b

}
.contents-text h2{
  font-family:'NanumSquare', sans-serif;
  font-size:32px;
  Line-height:1.38;
  font-weight: 600;
  color:#000000;

}
.contents-text h3{
  font-family:'NanumSquare', sans-serif;
  font-weight: 400;
  font-size:16px;
  line-height: 1.63;
  opacity: 0.7;
  padding-top:40px;
  color:#000000;
  width: 92%;
  float: left;
  font-weight: 300;

}

.red{
  color:#ff5543;
}

.white{
  color:#ffffff;
}
 
/* .contents-contents{
  text-align:left;
  color:#000000;
  padding-top:150px;

} */

.contents-right{
  width:50%;
  height:100%;
  float:left;
  text-align: left;
  vertical-align: middle;
  display:table-cell;
  padding: 0 auto;
  margin: 0 auto;
}


.appdownload{
  display: none !important;
}

.size{
  width:100%;
  height:100%;  
}

.link{
  cursor: pointer;
  padding:40px 35px 70px 0px;
}

img { 
  border:0 
}


.terms{
  max-width: 600px;
  margin:0 auto;
  padding: 20px;
}

.terms h2{
  margin-bottom: 20px;
  font-size: 1.375rem;
  line-height: 1;
  font-weight:600;
}

.terms h3{
  margin-top:20px;
  margin-bottom:10px;
  line-height:1;
  font-size:15px;
  font-weight:600;
}

.terms h4{
  font-weight:500;
  font-size:14px;
  float:left;
}

.terms p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: .8125rem;
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: .8125rem;
}

.ol{
  list-style-type: none;
}

.ul{
  list-style-type: circle
}

.table{
  text-align:left;
  border-bottom:  0.5px solid #ddd;
}

th, td{
  text-align: left;
  border: 0.5px solid #ddd ;
  padding: 7px 10px;
  
}

.team-line-top-desktop{
  display: block;
  width: 26px;
  height: 5px;
  background-color: #22262d;
  float: left;
}


table {
  width: 100%;
  text-align: center;
  border: 0.5px solid #ddd;
  border-bottom:  0.5px solid #ddd;
  border-collapse: collapse;
}

.Media-play-icon {
  width: 22px;
  height: 22px;
  float:left;
  padding:14px 0px 14px 14.5px;
}

 .container-fluid{
  width: 100%;
  align-content: center;
}  

.news-img-base{
  display: block;
}


/* display: none*/
.h3-mobile ,.contents-mobile, .mobile-sidebar, .mobile-slidebar, .menu-bar, .app-tablet
, .moditt-img-tablet,.section-mobile,.section1-mobile, .news-link-desktop-tablet,.news-img-mobile,.facebook-moditt-mobile, .footer-tablet, .app-mobile-section {
  display:none;
}


}
.section3{
    width:100%;
    height:580px;
    background-color: #ffffff;
}

.sectionimg2{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 100px;

}
.section4{
    width:100%;
    height:882px;
    background-color:#22262d;
   display: block;
}

.munse_app {
    display: none;
}

.contents-top{
    width:100%;
    height:50%;
}

.contents-bottom{
    width:100%;
    height:50%;
}

.contents-app{
    width:30%;
    padding:120px 0 0 0;
    float:left;
}


.app-description-bottom {
    width:70%;
    height:100%;
    float:left;
    
}
.app-description-bottom h1{
    font-size:25px;
    line-height: 1;
    font-weight:400;
    font-family:'NanumSquare', sans-serif;
    color:#ffffff;
    /* padding-top:80px; */
}

.app-description-bottom h2{
    font-size:17px;
    line-height: 1.76;
    font-weight: 400;
    opacity: 0.5;
    font-family:'NanumSquare', sans-serif;
    color: #ffffff;
    padding-top:20px;
}

.app-description-bottom h3{
    font-size:14px;
    line-height: 1;
    font-weight: 400;
    font-family:'NanumSquare', sans-serif;
    color:#24c1b0;
    padding-top:30px;
    text-decoration: underline;
    width: 106px;
    float: left;
    
}


.app-description {
    width:70%;
    height:100%;
    float:left;
    
}

.app-description h1{
    font-size:25px;
    line-height: 1;   
    font-family:'NanumSquare', sans-serif;
    color:#ffffff;
    padding-top:120px;
    font-weight:400;
}



.app-description h2{
    font-size:17px;
    line-height: 1.76;
    font-weight: 400;
    opacity: 0.5;
    font-family:'NanumSquare', sans-serif;
    color: #ffffff;
    padding-top:20px;
}

.app-description h3{
    font-size:14px;
    line-height: 1;
    font-weight: 400;
    font-family:'NanumSquare', sans-serif;
    color:#24c1b0;
    padding-top:30px;
    text-decoration: underline;
    width: 106px;
    float: left;
    
}
.go-to-web{
    width:100%;
}
.web{
    width:16px;
    height:16px;
    padding-left:7px;
    float:left;
    padding-top:30px;
  }
  
  

.google-apple{
    width:100%;
    height:50px;
    padding-top:52px;
}
.google-apple h1{
    font-weight: 400;
    font-family:'NanumSquare', sans-serif;
    line-height: 1;
    color: #ffffff;
    font-size:15px;
    padding-top:0;
    width:80%;
    padding: 17.5px 0px 17.5px 46.5px;

}

.google {
    width:180px;    
    height:100%;
    float:left;
    border-radius:0.5em;
    border: 1px solid #ffffff;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
}

.apple {
    width:180px;
    height:100%;    
    float:left;
    border-radius:0.5em;
    border: 1px solid #ffffff;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
}



.contents-app-bottom{
    width:30%;
    /* padding:80px 0 0 0; */
    float:left;
}

.app-icon {
    width: 120px;
    height: 120px;
    float:left;   
}

.app{
    width: 50%;
    height: 100%;
    float:left;
    padding-top: 50px;
    padding-bottom: 80px;
}
.section5{
    width:100%;
    height:893px;
    background: url(../../static/media/moditt-team-c-1@2x.eb97f010.jpg);
    background-size:cover;
    background-position:top;
    background: cover;
    margin-top: -1px;
}

.sectionimg4{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 100px;

}

.w-contents-left{
    width:50%;
    height:100%;
    float:left;
    
  }
  /* .w-contents-text-no-title {
    width:100%;
    height:520px;
    padding-top:220px;
    word-break: keep-all;
    
  }
  
  .w-contents-text-no-title h3{
    font-family:'NanumSquareRound', sans-serif;
    font-size: 17px;
    line-height: 1.76;
    font-weight: 400;
    color:#30343b
  }
   */
  .w-contents-text-right{
    width:100%;
    padding-top:505px;
    word-break: keep-all;
    
  }
  
  .w-contents-right{
    width:50%;
    height:100%;
    float: left;
  }
  .w-contents-text-right h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 28px;
    line-height: 1;
    color: #ffffff;
    /* width:600px; */
    float: left;
    font-weight:600;
  }
  
  .w-contents-text-right h3{
    font-family:'NanumSquare', sans-serif;
    font-size: 17px;
    line-height: 1.76;
    font-weight: 400;
    color:#ffffff;
    line-height: 30px;
    float: left;
    padding-top:30px;
  }
  
  .w-contents-text{
    /* width:85%;
    height:520px; */
    padding-top:120px;
    word-break: keep-all;
    
  }

  .w-contents-text .team-line{
    width:26px;
    height:5px;
    float:left;
    background-color:#ffffff;
    margin-top:120px;
    float:left;


  }
  
  .w-contents-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size:50px;
    line-height: 1;
    font-weight: 900;
    color:#ffffff;
  
  }
  .w-contents-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:35px;
    line-height: 1.29;
    color:#ffffff;
    padding-top:140px;
  
  }
  .w-contents-text h3{
    font-family:'NanumSquare', sans-serif;
    font-weight: 400;
    font-size:16px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:40px;
    color:#000000;
    width: 92%;
    float: left;
    font-weight: 300;
  
  }

 


.section6{
    width:100%;
    height:1820px;
}

.section6_top{
    width:100%;
    height:700px;
    background-color: #fafafa;
}

.three-img{
    width: 33%;
    float:left;
    padding-top:120px;
    height:260px;
}

.moditt-work{
    width:380px;
    height:260px;
    float:left;
}

.moditt-img{
    width:790px;
    height:360px;
    float:right;
    padding-right: 30px;
    padding-top:250px;
}

.section6_bottom{
    width:100%;
    height:1120px;
    background-color: #22262d;
}

.w-contents-left2{    
    height:20%;
    float:left;
}

.moditt-work-text{
    width: 380px;
    height: 50px;
    margin: 0;
    padding-top:260px;
}

.w-contents-text{
    /* width:85%;
    height:520px; */
    padding-top:120px;
    word-break: keep-all;
    
  }

  .w-contents-text .team-line-top{
    width:26px;
    height:5px;
    float:left;
    background-color:#ffffff;
    float:left;


  }
  


.moditt-work-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size:20px;
    line-height: 1;
    font-weight: 900;
    color:#30343b;
    padding-top:40px;
}


.moditt-work-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1.67;
    font-weight: 400;
    color:#30343b;
    padding-top:20px;
}

.w-contents-text{
    /* width:85%;
    height:520px; */
    padding-top:120px;
    word-break: keep-all;
    
  }

  .w-contents-text .team-line{
    width:26px;
    height:5px;
    float:left;
    background-color:#ffffff;
    margin-top:120px;
    float:left;


  }
  
  .w-contents-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size:35px;
    line-height: 1;
    color:#ffffff;
    padding-top:20px;
    font-weight:900;
  
  }
  .w-contents-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:35px;
    line-height: 1.29;
    font-weight: 900;
    color:#ffffff;
    padding-top:140px;
  
  }
  .w-contents-text h3{
    font-family:'NanumSquare', sans-serif;
     font-weight: 400;
    font-size:16px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:40px;
    color:#000000;
    width: 92%;
    float: left;
    font-weight: 300;
  
  }
  .box{
      width:520px;
      float: left;
      height: 179px;
      text-align: left;
      padding-top:20px;
  }

  .box2{
    width:520px;
    float: right;
    height: 179px;
    text-align: right;
    padding-top:20px;
    padding-right:30px;
}

  .box h1 {
    font-family:'NanumSquare', sans-serif;
    font-size:25px;
    line-height: 1;
    padding-top:20px;
    color:#ffffff;
    font-weight: 600;
  }


  .ham {
    font-family:'NanumSquare', sans-serif;
    font-weight: 900;
    font-size:25px;
    line-height: 1;
    padding-top:20px;
    color:#ffffff;
    text-align: right;
  }


  /* .box h1 {
    font-family:'NanumSquare', sans-serif;
    font-weight: 900;
    font-size:25px;
    line-height: 1;
    padding-top:20px;
    color:#ffffff;
  } */

  .box h2 {
    font-family:'NanumSquare', sans-serif;
    font-weight: 400;
    font-size:17px;
    line-height: 1.76;
    padding-top:19px;
    opacity: 0.5;
    color:#ffffff;
  }
  
  .ham-h2{
    font-family:'NanumSquare', sans-serif;
    font-weight: 400;
    font-size:17px;
    line-height: 1.76;
    padding-top:19px;
    opacity: 0.5;
    color:#ffffff;
    text-align: right;
  }
  .comment{
      width:120px;
      height:55px;
  }

  .comment2{
    width:120px;
    height:55px;
}

.comment21{
  width:120px;
  height:55px;
}
  .interview{
      width:100%;
      float:left;
  }


.footer{
    width:100%;
    height:200px;
    background-color:#22262d;
}
.footer-contents{

    width:72%;
    height:100%;
}

.footer-text{
    width:100%;
}

.copyright{
    width:53%;
    float:left;
}

.user{
    width:47%;
    float:left;
    
}

.user h5{
    float:left;
    padding-left:20px;
    opacity:1;
    font-weight: bold;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    line-height:1.67;
    cursor: pointer;
}

h6{
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:14px;
    opacity: 0.5;
    padding-top:60px;
    
}

.adress{
    width:100%;
    padding-top:20px;
}

.adress h5{
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    opacity: 0.5;
    line-height:1.67;
    padding-top:20px;
}

h5{
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    opacity: 0.5;
    line-height:1.67;
}

hr{
    opacity: 0.2;
    width:100%;
    background-color: #ffffff;
}


.opacity{
    opacity: 0.5 !important;
    line-height: 1.67 !important; 
    font-weight: 500 !important;
}



/* display : none */
.mobile-copyright {
    display:none;
}


.w-facebook-moditt{
    width:50%;
    height:100%;
    float:left;
}

.w-facebook-moditt h1{
    padding: 60px 0px 0px 0px;
    font-family:'NanumSquare', sans-serif;
    font-size:20px;
    line-height: 1;
    color:#ffffff;
    font-weight: 600;
}

.w-facebook-moditt h2{
    padding-top: 20px;
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1;
    opacity: 0.7;
    font-weight: 300;
    color:#ffffff;
}

.w-facebook-moditt h3{
    padding-top: 10px;
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1;
    opacity: 0.7;
    font-weight: 300;
    color:#ffffff;
}
.w-facebook-moditt h4{
    padding-top: 100px;
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1;
    opacity: 0.7;
    font-weight: 300;
    color:#ffffff;
}

.w-facebook-moditt h5{
    padding-top: 10px;
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1;
    opacity: 0.7;
    font-weight: 300;
    color:#ffffff;
}


.section7{
    width:100%;
    height:610px;
    background-color: #f0f0f0;
}

.b-contents-text{
    /* width:85%;
    height:520px; */
    word-break: keep-all;
    
  }

  .b-contents-text .team-line-top{
    width:26px;
    height:5px;
    float:left;
    background-color:#30343b;
    float:left;

  }

  .b-contents-text h1{
    font-family: 'NanumSquare', sans-serif;
    font-size: 35px;
    line-height: 1;
    color: #30343b;
    padding-top: 20px;
    font-weight: 900;
  }
  
  .b-contents-text-no-title {
    width:520px;
    padding-top:220px;
    word-break: keep-all;
    
  }
  
  .b-contents-text-no-title h3{
    font-family:'NanumSquare', sans-serif;
    font-size: 17px;
    line-height: 1.76;
    font-weight: 400;
    color:#30343b;
    width:520px;
    float: left;
  }

  .join{
        width:180px;
        height:50px;    
        float:left;
        border-radius:0.5em;
        background-color:#30343b;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        margin-top:50px;
  }

  .join h1{
      color:white;
      font-weight: 400;
      font-family:'NanumSquare', sans-serif;
      line-height: 1;
      color: #ffffff;
      font-size:15px;
      padding-top:0;
      width:80%;
      padding: 18px 40px 17px 40px;
  }

.section8{
    width:100%;
    height:997px;
    background-color: #ffffff;
}

.news-img{
        width: 50%;
        float:left;
        padding-top:80px;
        height:356px;
        
}

.news{
    width:520px;
    height:356px;
}

.news-text{
    width: 520px;
    height: 75px;
    margin: 0;
}


.news-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size:20px;
    line-height: 1;
    font-weight: 600;
    color:#30343b;
    padding-top:50px;
}


.news-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1.67;
    font-weight: 400;
    color:#30343b;
    padding-top:20px;
    height: 75px;
}

.news-text h3{
    font-family:'NanumSquare', sans-serif;
    font-size:14px;
    line-height: 1;
    font-weight: 600;
    color:#30343b;
    padding-top:30px;
    text-decoration: underline;
    float:left;
}

.news-web{
    width:16px;
    height:16px;
    float:left;
    padding-top:30px;
    padding-left:7px;
  }


  
.section9{
    width:100%;
    height:145px;
    background-color:#f0f0f0;
}

.facebook-moditt{
    width:50%;
    height:100%;
    float:left;
}

.facebook-moditt h1{
    padding: 60px 0px 60px 0px;
    font-family:'NanumSquare', sans-serif;
    font-size:25px;
    line-height: 1;
    color:#30343b;
    font-weight: 600;
}

.facebook{
    width: 181px;
    height: 50px;  
    float:left;
    border-radius:0.5em;
    border: 1px solid #30343b;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    margin-top:46px;
    margin-left:319.5px
}

.facebook h1{
  color:white;
  font-weight: 600;
  font-family:'NanumSquare', sans-serif;
  line-height: 1;
  color: #30343b;
  font-size:15px;
  padding-top:0;
  width:80%;
  padding: 17px 34px 17px 34px;
}

