@import url("../base.css");

.section5{
    width:100%;
    height:893px;
    background: url("../../img/moditt-team-c-1@2x.jpg");
    background-size:cover;
    background-position:top;
    background: cover;
    margin-top: -1px;
}

.sectionimg4{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 100px;

}

.w-contents-left{
    width:50%;
    height:100%;
    float:left;
    
  }
  /* .w-contents-text-no-title {
    width:100%;
    height:520px;
    padding-top:220px;
    word-break: keep-all;
    
  }
  
  .w-contents-text-no-title h3{
    font-family:'NanumSquareRound', sans-serif;
    font-size: 17px;
    line-height: 1.76;
    font-weight: 400;
    color:#30343b
  }
   */
  .w-contents-text-right{
    width:100%;
    padding-top:505px;
    word-break: keep-all;
    
  }
  
  .w-contents-right{
    width:50%;
    height:100%;
    float: left;
  }
  .w-contents-text-right h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 28px;
    line-height: 1;
    color: #ffffff;
    /* width:600px; */
    float: left;
    font-weight:600;
  }
  
  .w-contents-text-right h3{
    font-family:'NanumSquare', sans-serif;
    font-size: 17px;
    line-height: 1.76;
    font-weight: 400;
    color:#ffffff;
    line-height: 30px;
    float: left;
    padding-top:30px;
  }
  
  .w-contents-text{
    /* width:85%;
    height:520px; */
    padding-top:120px;
    word-break: keep-all;
    
  }

  .w-contents-text .team-line{
    width:26px;
    height:5px;
    float:left;
    background-color:#ffffff;
    margin-top:120px;
    float:left;


  }
  
  .w-contents-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size:50px;
    line-height: 1;
    font-weight: 900;
    color:#ffffff;
  
  }
  .w-contents-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:35px;
    line-height: 1.29;
    color:#ffffff;
    padding-top:140px;
  
  }
  .w-contents-text h3{
    font-family:'NanumSquare', sans-serif;
    font-weight: 400;
    font-size:16px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:40px;
    color:#000000;
    width: 92%;
    float: left;
    font-weight: 300;
  
  }

 

