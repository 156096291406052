/* font - face */
/* @import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css'); */
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
/* 기본 값 초기화 */
  *{
    margin:0 auto;
    padding:0 auto;
  }

  *:focus { 
    outline:none; 
  }  
  

  h1,h2,h3,h4,h5,h6 {
    font-size:100% 
  }

/* 좌우 border */
.solid {
  border:0.5px 
  solid #eeeeee;
  border-top-style: none;
  border-bottom-style: none;
}

/* 공통 section css 작업 부분 */
/* Element{
  height:500px !important;
} */
.section-contents{
  width:64%;
  height:100%;
}

.indicate{
  width:1%;
  height:54%;
  padding-right:40px;
  padding-top:23%;
  float:right !important;

}

.indicateOn{
  width:12px;
  height:12px;
  float:left;
}

.indicateOff{
  width:12px;
  height:12px;
  float:left;
}


.contents-left{
  width:50%;
  height:100%;
  float:left;
  
}
.contents-text-no-title {
  width:100%;
  height:520px;
  padding-top:220px;
  word-break: keep-all;
  
}

.contents-text-no-title h3{
  font-family:'NanumSquare', sans-serif;
  font-size: 17px;
  line-height: 1.76;
  font-weight: 400;
  color:#30343b;
  word-break: break-all;
  /* width:600px; */
}

.contents-text-right{
  width:100%;
  padding-top:230px;
  word-break: keep-all;
  
}

.contents-text-right h2{
  font-family:'NanumSquare', sans-serif;
  font-size: 28px;
  line-height: 1;  
  color:#30343b;
  font-weight:600;
}

.contents-text-right h3{
  font-family:'NanumSquare', sans-serif;
  font-size: 17px;
  line-height: 1.76;
  font-weight: 400;
  color:#30343b;
  position: absolute;
  padding-top:30px;
  word-break: break-all;
  /* width:600px; */
}

.contents-text{
  /* width:85%;
  height:520px; */
  padding-top:120px;
  word-break: keep-all;
  
}

.contents-text h1{
  font-family:'NanumSquare', sans-serif;
  font-size:50px;
  line-height: 1;
  color:#30343b;
  font-weight:900;

}
.contents-text h2{
  font-family:'NanumSquare', sans-serif;
  font-size:32px;
  Line-height:1.38;
  font-weight: 600;
  color:#000000;

}
.contents-text h3{
  font-family:'NanumSquare', sans-serif;
  font-weight: 400;
  font-size:16px;
  line-height: 1.63;
  opacity: 0.7;
  padding-top:40px;
  color:#000000;
  width: 92%;
  float: left;
  font-weight: 300;

}

.red{
  color:#ff5543;
}

.white{
  color:#ffffff;
}
 
/* .contents-contents{
  text-align:left;
  color:#000000;
  padding-top:150px;

} */

.contents-right{
  width:50%;
  height:100%;
  float:left;
  text-align: left;
  vertical-align: middle;
  display:table-cell;
  padding: 0 auto;
  margin: 0 auto;
}


.appdownload{
  display: none !important;
}

.size{
  width:100%;
  height:100%;  
}

.link{
  cursor: pointer;
  padding:40px 35px 70px 0px;
}

img { 
  border:0 
}


.terms{
  max-width: 600px;
  margin:0 auto;
  padding: 20px;
}

.terms h2{
  margin-bottom: 20px;
  font-size: 1.375rem;
  line-height: 1;
  font-weight:600;
}

.terms h3{
  margin-top:20px;
  margin-bottom:10px;
  line-height:1;
  font-size:15px;
  font-weight:600;
}

.terms h4{
  font-weight:500;
  font-size:14px;
  float:left;
}

.terms p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: .8125rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: .8125rem;
}

.ol{
  list-style-type: none;
}

.ul{
  list-style-type: circle
}

.table{
  text-align:left;
  border-bottom:  0.5px solid #ddd;
}

th, td{
  text-align: left;
  border: 0.5px solid #ddd ;
  padding: 7px 10px;
  
}

table {
  width: 100%;
  text-align: center;
  border: 0.5px solid #ddd;
  border-bottom:  0.5px solid #ddd;
  border-collapse: collapse;
}

.Media-play-icon {
  width: 22px;
  height: 22px;
  float:left;
  padding:14px 0px 14px 14.5px;
}

 .container-fluid{
  width: 100%;
  align-content: center;
}  

.news-img-base{
  display: block;
}

.team-line-top-desktop{
  display: block;
  width: 26px;
  height: 5px;
  background-color: #22262d;
  float: left;
}


/* display: none*/
.h3-mobile ,.contents-mobile, .mobile-sidebar, .mobile-slidebar, .menu-bar, .app-tablet
, .moditt-img-tablet,.news-img-tablet,.news-link-tablet,
.comment2,.section-mobile,.section1-mobile, .news-link-desktop-tablet,.news-img-mobile,.facebook-moditt-mobile, .footer-tablet, .app-mobile-section {
  display:none;
}


