@import url("../base.css");

.section1{
    width:100%;
    height:588px;
    background-color: #ffffff;
     display: block;   
     position: static;
     align-content: center;
    /* position: static; */
}

.section1_bgimg{
    background-size: 1440px 100%;
}

.contents{
    width:100%;
    height:90%;
}
.contents-text-top{
    width:570px;
    height:520px;
    margin: 50px 100px;
    word-break: keep-all;
  }

.top-app{
    width:100%;
    height:25%;
    padding-top:60px;
}

.appstore, .googleplay{
    width:50%;
    float:left;
    width:186px;
    height:46px;
    padding-right:10px;
    cursor: pointer;
}

.sectionimg{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 100px;
    

}

.top-text{
    font-family: 'NanumSquare', sans-serif;
    font-weight:300; 
    font-size:32px;
    Line-height:1.38;
    color:#ffffff;

}

.top-title{
    font-family: 'NanumSquare', sans-serif;
    font-weight:600; 
  font-size:32px;
  Line-height:1.38;
  color:#ffffff;
}

.top-contetns{
    font-family: 'NanumSquare', sans-serif;
    font-weight:400; 
    font-size:16px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:40px;
    color:#ffffff;
}


.appdownload{
    display:none;
}
