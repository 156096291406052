@import url("../base.css");

.section7{
    width:100%;
    height:610px;
    background-color: #f0f0f0;
}

.b-contents-text{
    /* width:85%;
    height:520px; */
    word-break: keep-all;
    
  }

  .b-contents-text .team-line-top{
    width:26px;
    height:5px;
    float:left;
    background-color:#30343b;
    float:left;

  }

  .b-contents-text h1{
    font-family: 'NanumSquare', sans-serif;
    font-size: 35px;
    line-height: 1;
    color: #30343b;
    padding-top: 20px;
    font-weight: 900;
  }
  
  .b-contents-text-no-title {
    width:520px;
    padding-top:220px;
    word-break: keep-all;
    
  }
  
  .b-contents-text-no-title h3{
    font-family:'NanumSquare', sans-serif;
    font-size: 17px;
    line-height: 1.76;
    font-weight: 400;
    color:#30343b;
    width:520px;
    float: left;
  }

  .join{
        width:180px;
        height:50px;    
        float:left;
        border-radius:0.5em;
        background-color:#30343b;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        margin-top:50px;
  }

  .join h1{
      color:white;
      font-weight: 400;
      font-family:'NanumSquare', sans-serif;
      line-height: 1;
      color: #ffffff;
      font-size:15px;
      padding-top:0;
      width:80%;
      padding: 18px 40px 17px 40px;
  }
