@import url("../base.css");

.section3{
    width:100%;
    height:580px;
    background-color: #ffffff;
}

.sectionimg2{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 100px;

}