@import url("../base.css");

.section9{
    width:100%;
    height:145px;
    background-color:#f0f0f0;
}

.facebook-moditt{
    width:50%;
    height:100%;
    float:left;
}

.facebook-moditt h1{
    padding: 60px 0px 60px 0px;
    font-family:'NanumSquare', sans-serif;
    font-size:25px;
    line-height: 1;
    color:#30343b;
    font-weight: 600;
}

.facebook{
    width: 181px;
    height: 50px;  
    float:left;
    border-radius:0.5em;
    border: 1px solid #30343b;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    margin-top:46px;
    margin-left:319.5px
}

.facebook h1{
  color:white;
  font-weight: 600;
  font-family:'NanumSquare', sans-serif;
  line-height: 1;
  color: #30343b;
  font-size:15px;
  padding-top:0;
  width:80%;
  padding: 17px 34px 17px 34px;
}
