@media (min-width: 0px) and (max-width: 1367px) {   

      .bm-overlay{
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        opacity: 1;
        transition: opacity 0.3s ease 0s;
    }
    
      .menubtn{
        /* background:none;
        position:absolute;
        top:0;
        left:0;
        line-height:45px;      
        color:#999;
        cursor:pointer;
        width:14px;
        height:14px;
        text-align:center;
        padding:12px 20px 12px 20px; */
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:13px 12.5px 0px 0px;
        cursor:pointer;
        z-index: 999999;
      }

      /* .menubtn.shown{
        display:none;
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:20px 12.5px 0px 0px;
        cursor:pointer;
        z-index: 999999;
      } */
     
      .menubarblack{
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:20px 12.5px 0px 0px;
        cursor:pointer;
        z-index: 999999;
      }

      .modittlogo{
        width:120px;
        height:30px;
        float:left; 
        left:0;
        padding-top:10px;
      }

      

      .menulist-top{
        height:49px;
        padding:0px 20px 0px 20px;
      }
      .cross{
        background:none;
        position:absolute;
        top:0;
        right:0;  
        color:#999;
        cursor:pointer;
        width:24px;
        height:24px;
        text-align:center;
        float:left;
        padding:13px 12.5px 0px 583px;
        z-index: 999999;
      }

      .menulist{
        background: #ffffff;
        /* padding: 0px 30px 0px 30px !important; */
        font-size: 16px;
        /* width:100%; */
        /* height:206px !important; */
        text-align:left;
        font-family: 'NanumSquare', sans-serif;
        font-weight:700; 
        height:269px;
        z-index: 999999;
        position: fixed;
        width:100%;
        
    }

    .menulist-fixed-top{
      height:49px;
      padding:0px 20px 0px 20px;
    }
    .menulist-fixed{
      background: #ffffff;
      /* padding: 0px 30px 0px 30px !important; */
      font-size: 16px;
      /* width:100%; */
      /* height:206px !important; */
      text-align:left;
      font-family: 'NanumSquare', sans-serif;
      font-weight:700; 
      height:269px;
      z-index: 999999;
      position: fixed;
      width:100%;
      
  }
  .menulist-fixed ul {
          margin: 0; 
          padding: 0; 
          height:179px;
          padding-left:20px;
          padding-top:40px;
          list-style-type: none; 
          list-style-image: none;
          
          z-index: 999999;
        }
      .menulist-fixed li {
          display: block;   
          padding:0px 0px 25px 0px;
          cursor: pointer;
        }

      .menulist-fixed ul li a { 
          text-decoration:none;  
          margin: 0px; 
          color:#000000;
        }
      .menulist-fixed ul li a:hover {  
          color: #000000; 
          text-decoration:none;
        }
      .menulist-fixed a{
          text-decoration:none; 
          color:#000000;
        }
      .menulist-fixed a:hover{
          text-decoration:none; 
          color:#000000;
        }
      .about{
        font-family:'NanumSquare', sans-serif;   
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #30343b;
        float:left;
        
      }

      .services, .team, .menu-news{
        font-family:'NanumSquare', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #30343b;
        float:left;
        height:41px;
        
      }


    .desktop-menu{
      width:100%;
    }
    .menulist ul {
          margin: 0; 
          padding: 0; 
          height:179px;
          padding-left:20px;
          padding-top:40px;
          list-style-type: none; 
          list-style-image: none;
        }
      .menulist li {
          display: block;   
          padding:0px 0px 25px 0px;
          cursor: pointer;
        }

      .menulist ul li a { 
          text-decoration:none;  
          margin: 0px; 
          color:#000000;
        }
      .menulist ul li a:hover {  
          color: #000000; 
          text-decoration:none;
        }
      .menulist a{
          text-decoration:none; 
          color:#000000;
        }
      .menulist a:hover{
          text-decoration:none; 
          color:#000000;
        }
      .about{
        font-family: 'NanumSquare', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #30343b;
        float:left;
        
      }

      .services, .team, .menu-news{
        font-family: 'NanumSquare', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #30343b;
        float:left;
        height:41px;
        
      }


   
      .pay{
        padding-top:25.5px;
        padding-bottom:40px;
      }
      
      .glyphicon-home{
        color:white; 
        font-size:1.5em; 
        margin-top:5px; 
        margin:0 auto;
      }

      header{
        display:inline-block; 
        font-size:12px;}

      p {
        padding-left:0px;
      }
      a{color:#336699;}


      .menulist hr{
        width:90%;
        float:center;
      }

      /*display : none */
      .menu{
          display:none;
      }

 
    }
