@import url("../base.css");

.wrap{    
    width:100%;
    height:100%;
    padding-bottom: 84px;
}

.all_header{
    width:100%;
    height:720px;
    background: url("../../img/moditt-spot-bg@3x.jpg");
    background-size:cover;
    background-position:top;
    background: cover;
    z-index :2;
}

.header{
    width:100%;
    height: 130px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    color: #ffffff;
    z-index: 0;
    position: fixed;
    transition: top 0.2s ease-in-out;
        /* text-align: center;
    position: fixed !important;
    display:flex;   
    */
}

.header.shown{
    display: none;
    width:100%;
    height: 130px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    color: #ffffff;
    z-index: 0;
    position: fixed;
    transition: top 0.2s ease-in-out;
        /* text-align: center;
    position: fixed !important;
    display:flex;
   
    */
}



.header-fixed{  
    visibility: hidden;  
    width:100%;
    height: 100px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    background-color: #ffffff;
    z-index :1;
    position: fixed;
    border-bottom: .5px solid #eee;
    /* text-align: center;
    position: fixed !important;
    display:flex;
    z-index :99999; */
    
}


.header-fixed.shown{  
    visibility: visible;   
    width:100%;
    height: 100px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    background-color: #ffffff;
    z-index :1;
    position: fixed;
    border-bottom: .5px solid #eee;
    /* text-align: center;
    position: fixed !important;
    display:flex;
    z-index :99999; */
    
}

.menu-fixed{
    height:100%;
    margin:0 auto;
    font-family: 'NanumSquare', sans-serif;
    font-weight:700; 
    float:right;
    text-align:center;
}

.menu-fixed li{
    float:left;
    list-style:none;
    line-height: 100%;
    padding: 40px 35px 40px 0px;
}
.moditt_logo_fixed{    
    float:left;
    /* width:200px; */
    height:40px;
    margin:0 auto;
    padding:0 auto;
    text-align: left;
    padding: 30px 0px 30px 50px;
    cursor: pointer;
}

.moditt_logo{    
    float:left;
    width:200px;
    height:50px;
    margin:0 auto;
    padding:0 auto;
    text-align: left;
    padding: 40px 0px 40px 50px;
    cursor: pointer;
}


.menu{
    height:100%;
    margin:0 auto;
    font-family: 'NanumSquare', sans-serif;
    font-weight:700; 
    float:right;
    text-align:center;
}

.menu li{
    float:left;
    list-style:none;
    line-height: 100%;
    padding: 40px 35px 70px 0px;
}

.header-nav{
    float:right;
    font-size:16px;
    color: #000000;
    margin:0 auto;
    padding: 0.5%;
    font-family: 'NanumSquare', sans-serif;
    font-weight:700; 
    padding-right:2%;
}
.header_title {
     position:relative; 
     width:752px;
     height:75px; 
     left:0;
     right:0;
     top:45%;
     bottom:10%;
     z-index: 99999;
    } 

p{
    height:100%;
    font-family:  'NanumSquare', sans-serif;
    font-weight:900;
    float:center;
    text-align:center;
    font-size: 75px;
    line-height: 1;
    color:#ffffff;
}