@import url("../base.css");

.section8{
    width:100%;
    height:997px;
    background-color: #ffffff;
}

.news-img{
        width: 50%;
        float:left;
        padding-top:80px;
        height:356px;
        
}

.news{
    width:520px;
    height:356px;
}

.news-text{
    width: 520px;
    height: 75px;
    margin: 0;
}


.news-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size:20px;
    line-height: 1;
    font-weight: 600;
    color:#30343b;
    padding-top:50px;
}


.news-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1.67;
    font-weight: 400;
    color:#30343b;
    padding-top:20px;
    height: 75px;
}

.news-text h3{
    font-family:'NanumSquare', sans-serif;
    font-size:14px;
    line-height: 1;
    font-weight: 600;
    color:#30343b;
    padding-top:30px;
    text-decoration: underline;
    float:left;
}

.news-web{
    width:16px;
    height:16px;
    float:left;
    padding-top:30px;
    padding-left:7px;
  }


  