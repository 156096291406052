@import url("../base.css");

.section2{
    width:100%;
    height:360px;
    background-color:#ffffff;
    background: url("../../img/moditt-message@3x.png");
    background-size:cover;
    background-position:top;
    background: cover;
    max-width:1920px;
}


.sectionimg1{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 0px;
}


