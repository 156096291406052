  /* Device = mobile , Screen = 414px to 767px */
  @media (min-width: 0px) and (max-width: 767px) {  
    .wrap{    
        width:100%;
        height:100%;
        padding-bottom: 64px !important;
    }
    
    .solid {
        border:1px 
        solid #dddddd;
        border-top-style: none;
        border-bottom-style: none;
        border-left-style:none;
        border-right-style:none;
      }



    .size{
        width:100% !important; 
      }

    .section-contents{
        width:280px !important;
        height:100%;
    }

    .moditt_logo{    
        float:left;
        width:120px !important;
        height:30px !important;
        margin:0 auto;
        padding:0 auto;
        text-align: center;
        padding: 10px 0px 10px 20px !important;
    }

    .moditt_logo_fixed{
        width:120px !important;
        height:30px !important;
        float:left;
        padding: 10px 0px 10px 20px !important;
        z-index: 999999 !important;
        position: absolute;
        
    }
    .nav-menu{
        width:95%;
        height:49px; 
        display:none;      
    }

    .menu-fixed{
        display: none;
    }

    .menu-bar{
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:12.5px;
        cursor:pointer;
    }
    .navbar{
        width:100% !important;
        height:100%;
        margin:0 auto;
        font-family:'NanumSquare', sans-serif;
        text-align:center;
    }

    .mobile-slidebar{
        display: block;
    }


    .all_header{
        height:320px !important;
    }

    .header_title{
        top:30%;
        width:280px !important;
    }

    .header{
        height:50px;
    }

    .header-fixed{  
        visibility: hidden;  
        width:100%;
        height: 50px;
        font-family: 'NanumSquare', sans-serif;
        font-size:20px;
        background-color: #ffffff;
        z-index :1;
        position: fixed;
        border-bottom: .5px solid #eee;
        /* text-align: center;
        position: fixed !important;
        display:flex;
        z-index :99999; */
        
    }
    
    
    .header-fixed.shown{  
        visibility: visible;   
        width:100%;
        height: 50px;
        font-family: 'NanumSquare', sans-serif;
        font-size:20px;
        background-color: #ffffff;
        z-index :1;
        position: fixed;
        border-bottom: .5px solid #eee;
        /* text-align: center;
        position: fixed !important;
        display:flex;
        z-index :99999; */
        
    }
    .change_life_style{
        width: 280px;
        height: 40px;
        font-family: 'NanumSquare', sans-serif;
        font-size: 30px;
        
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding-left:0px !important;
        padding-top:50px;
    }
    /* .menu{
        height:100%;
        margin:0 auto;
        font-family: 'Noto Serif KR', sans-serif;
        font-weight:700; 
        float:right;
        text-align:center;
        font-size:14px;
    } */
/*     
    .menu li{
        float:left;
        list-style:none;
        line-height: 100%;
        padding: 25px 30px 25px 0px;
    } */
    
    .header-nav{
        float:right;
        font-size:16px;
        color: #000000;
        margin:0 auto;
        padding: 0.5%;
        font-family: 'NanumSquare', sans-serif;
        padding-right:2%;
    }

.section1{
    width:100% !important;
    height:315px !important;
    /* background-repeat: no-repeat !important;
    background-position:center !important;      */
    /* background-position : center !important;
    background-repeat : no-repeat !important; */
    }
    
.contents{
    width:100% !important;
    height:90% !important;
}

.contents-left{
    width:100% !important;
    height:25px !important;
    float:left;
    text-align: center !important;
    vertical-align: middle;
    display:table-cell;
    padding: 0 auto;
    margin: 0 auto;
  }

.contents-text{
    padding-top:50px !important;
    float:left;
}
.contents-right{
    width:100% !important;
    height:65% !important;
    float:left ;
    /* text-align: center !important; */
    /* vertical-align: middle; */
    display:table-cell;
    padding: 0 auto;
    margin: 0 auto;
  }
.contents-text-right{
    padding-top:20px;
}

.contents-text-right h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-top:50px;
}

.contents-text-right h3{
    padding-top:20px;
    font-family:'NanumSquare', sans-serif;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #30343b;
    width:280px;
    z-index: 1;
    position: initial;
}

.section1-mobile{
    display: block;
}
.contents-contents{
    text-align:center !important; 
    color:#000000;
    padding-top:0px;
  
  }

.contents-text{
    text-align:center !important;
}

.contents-text-top{
    width:100% !important;
    height:45% !important;
    padding-top:100px !important;
    margin: 0px 0px !important;
    word-break: keep-all;
  }

.top-app, .indicate{
    display:none;
}
  

.sectionimg{
    width:inherit;
    max-width:100%;
    width:470.8px;
    height:480px;
    padding:50px 100px !important;

}

.top-text{
    font-size:32px;
    Line-height:1.38;
    color:#ffffff;
    text-align: center;

}

.top-title{
    font-family: 'NanumSquare', sans-serif;
  font-size:32px;
  Line-height:1.38;
  color:#ffffff;
  text-align:center;
}

.top-contetns{
    font-family:'NanumSquare', sans-serif;
    font-size:14px !important;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:30px;
    color:#ffffff;
    text-align:center;
}
.indicate-width{
    width:100%;
    float:left;
    text-align: center;
}
.indicate{
    width:12px;
    height:12px;
    text-align: center;
}

/* section2*/
.section2{
    width:100% !important;
    height:88.3px !important;
    
}
.sectionimg1{
    width:inherit;
    max-width:100%;
    width: 470.8px;
    height:480px;
    padding:0px 0px;
}
.contents-text h1{
    font-size: 25px !important; 
    line-height: 1;
    padding-bottom:50px;

  
  }
  .contents-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:28px;
    Line-height:1.38;
    font-weight: 300;
    color:#000000;
    text-align: center;
  
  }
  .contents-text h3{
    font-family:'NanumSquare', sans-serif;
    font-size:14px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:50px;
    color:#000000;
    text-align: center;
    width:100%;
  }
  .section3{
    width:100%;
    height:353px !important;
    background-color:#fafafa;
}

.contents-text-no-title{
    padding-top:20px;
    font-family:'NanumSquare', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #30343b;
    height:100%;
}   

.contents-text-no-title h3{
    padding-top:50px;
    font-family:'NanumSquare', sans-serif;
    font-size: 12px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #30343b;
}   

.section4{
    width:100%;
    height:1064px !important;
}
.munse_app {
    display: block;
}
.app-mobile{
    display:block;
}
.app-mobile-section{
    width:100%;
    height:25%;
    float:left;
    padding-top:50px;   
    display: block;
}

.app-icon{
    width:60px !important;
    height:60px !important;
}

.contents-app{
    padding:0px !important;
    width:  0px !important;
}

.app-description {
    padding-left:85px;
}
.app-description h1{
    font-family: 'NanumSquare', sans-serif;
    font-size: 17px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:0px !important;
    float: left;
}

.google-maum{
    width: 280px;
    height: 37.5px;
    float: left;
    border-radius: 0.5em;
    border: 1px solid #ffffff;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;

}

.google-maum .Media-play-icon{
    padding:10.2px 0px 10.2px 82px;
}

.google-apple h1{
    font-size:12px !important;
    padding:12.9px 9px 12.8px 31px !important;
}

.Media-play-icon{
    width:17px !important;
    height:17px;
    padding: 10.2px 0px 10.2px 9px;
}

.google, .apple{
    width:135px !important;
    height:37.5px !important;
}



.web {
    padding-top:20px !important;
}

.app-description h2{
    opacity: 0.5;
    font-family:'NanumSquare', sans-serif;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:10px !important;
    float:left;
}


.google-apple{
    padding-top:0 !important;
}


.app{
    padding-top:32px !important;
    padding-bottom:0px !important;
    width:135px !important;
    
}

.app-2{
    padding-top:32px !important;
    padding-bottom:0px !important;
    width:135px !important;
    padding-left:10px;
    float: right;
    
}
.go-to-web h3{
    padding-top: 20px !important;
    padding-left:0px;
}

.section5{
    width:100%;
    height:540px !important;
}

.w-contents-left{
    width:100% !important;
    height: 206px !important;
}

.w-contents-text h1{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2 !important;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:60px !important;
}

.w-contents-text h2{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:59px !important;
}

.w-contents-text{
    padding-top:0px !important;
}

.w-contents-text .team-line {
    margin-top:50px !important;
    width: 16px !important;
    height: 3px !important;
}

.w-contents-right{
    width:100% !important;
    height:50% !important;
}

.w-contents-text-right{
    padding-top:50px !important;
    word-break:break-all !important;
}

.w-contents-text-right h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 20px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25 !important;
    letter-spacing: normal;
    color: #ffffff;
    width: 278px;
}

.w-contents-text-right h3{
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 22px !important;
    color: #ffffff;
    float: left;
    opacity: 0.5;
    padding-top: 20px !important;
}



.sectionimg4{
    width:inherit;
    max-width:100%;
    width: 470.8px !important;
    height:480px !important;
    padding:0px 0px !important; 

}
.section6 {
    height:1922px !important;
}
.section6_top{
    width:100% !important;
    height:1036px !important;
}




/* .section6-tablet{
    position:absolute;
    float: center;
    align-content: center
} */

.three-img{
    width: 100% !important;
    float: left;
    padding-top: 50px !important;
    height: 282px !important;
    padding-bottom:-10px;
}

.moditt-work{
    width: 280px !important;
    height: 192px !important;
}

.moditt-work-text{
    float: left;
    padding-top:20px !important;
    width:100% !important;
}

.moditt-work-text h1{
    font-size: 16px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #30343b;
    padding-top:0 !important;
}

.moditt-work-text h2{
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83 !important;
    letter-spacing: normal;
    color: #30343b;
    padding-top: 10px !important;
}

.moditt-img-tablet{
    width:100% !important;
    height: 146px !important;
    display: block;
}

.team-line-top{
    width:16px !important;
    height: 3px !important;
    margin-top:50px;
}

.team-line-top-career{
    display: block;
    width:16px !important;
    height: 3px !important;
    margin-top:0px;
    background-color: #22262d;
    float: left;
}

.section6_bottom{
    height:886px !important;
}

.w-contents-left2{
    height:127px !important;
    width:100%;
}

.box{
    padding-top:0px !important;
    width: 100% !important;
    height:157px !important;
    padding-bottom: 40px;
}

.box2{
    width:100% !important;
    float: left !important;
    height: 157px !important;
    text-align: left !important;
    padding-top:0px !important;
    padding-bottom: 40px;
}
.ham {
    font-family:'NanumSquare', sans-serif;
    font-size:16px !important;
    line-height: 1;
    padding-top:56.7px !important;
    color:#ffffff;
    text-align: left !important;
  }


.ham-h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 1.83 !important; 
    padding-top:10px !important;
    opacity: 0.5;
    color:#ffffff;
    text-align: left !important;
  }

.box h1{
    font-size: 16px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff; 
    padding-top:20.3px !important;
}

.box h2{
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83 !important; 
    letter-spacing: normal;
    color: #ffffff;
    padding-top:10px !important;
}

.interview-right{
    float: left !important;
}

.comment{
    width:80px !important;
    height:36.7px !important;
}

.comment2{
    width:80px !important;
    height:36.7px !important;
    float: left;
    
}
.contents-top{
    width:100%;
    height:62%;
}

.contents-mus{
    width: 57%;
    height:100%;
}
.contents-mus-text{
    text-align: center;
    padding-top:80px;
}

h4{
    font-family: 'NanumSquare', sans-serif;
    font-size: 16px !important;
    line-height: 1.44;
    text-align: center;
    color: #000000;
    padding: 20px 20px;
}

.appicon{
    width:100px;
    height:100px;
    
}

.section7{
    height: 349px !important;
}

.b-contents-text h1{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2 !important;
    letter-spacing: normal;
    padding-top:6px !important;
    font-weight: bold;
}

.b-contents-text-no-title{
    padding-top:20px !important;
    word-break: break-all !important;
    width:280px !important;
}

.b-contents-text-no-title h3{
    width:100% !important;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83 !important;
    letter-spacing: normal;
    color: #30343b;
    padding-top:60px;
}

.join{
    margin-top:30px !important;
    width:280px !important;
    height:37.50px !important;
}

.join h1{
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1 !important;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width:80px !important;
    padding: 12.5px 100px 13px 100px !important;
}

.section8{
    height: 927px !important;
}

.news{
    width:280px !important;
    height: 192px !important;
}

.news-img{
    width:100% !important;
    padding-top:0px !important;
    height: 389px !important;
}

.news-text{
    width:100% !important;
}

.news-text h1{
    float:left;
    font-size: 16px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-top:20px !important;
}
.news-link-tablet{
    display: block;
    float:left;
} 


.news-text h3{
    padding-top:20px !important;
    padding-left:0px !important;
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 1.17 !important;
    color: #30343b;
    padding-top: 20px;
}

.news-web{
    padding-top:20px !important;
}

.news-text h2{
    float:left;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83 !important;
    letter-spacing: normal;
    color: #30343b;
    padding-top: 10px !important;
}

.section9{
    height:134px !important;
}
.facebook-moditt{
    width:280px !important
}

.facebook-moditt h1 {
    font-size:16px !important;
    padding:30px 20px 0px 40px !important;
}


.facebook-moditt-mobile h1 {
    font-size:16px !important;
    padding:12.8px 94.5px 12.8px 94.5px !important;
}

.facebook{
    margin-left:0px !important;
    margin-top:20px !important;
    width:280px !important;
    height:37.5px !important;   
}

.facebook h1{
    font-size: 12px !important;
    width:91px !important;
}
.contents-app-down{
    width:57%;
    height:100%;
    text-align:center;
    margin:0 auto;
    padding:0 auto;
}

.contents-app-text{
    text-align: center;
    
}
.appstore-bottom, .googleplay-bottom{    
    display: none;
}

.appdownload{
    text-align:center;
    width:200px;
    height:46px;
    padding-top:30px;
    display:block !important;
    cursor: pointer;
}

.contents-bottom{
    width:100%;
    height:38%;
    text-align:center;
}
.footer{
    width:100%;
    height:246px !important;
}

.w-facebook-moditt{
    width:100% !important;
    height: 50% !important;
}

.w-facebook-moditt h1
{
    padding: 40px 0px 20px 0px !important;
    font-family: 'NanumSquare', sans-serif;
    font-size: 16px !important;
    line-height: 1;
    color: #ffffff; 
}

.w-facebook-moditt h2{
    padding-top: 20px !important;
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 1;
    opacity: 0.7 !important;
    color: #ffffff;
}
.w-facebook-moditt h3{
    padding-top: 10px;
    font-family: 'NanumSquare', sans-serif;
    font-size: 12px !important;
    line-height: 1;
    opacity: 0.7;
    color: #ffffff;
}

.w-facebook-moditt h4{
    padding-top:40px !important;
    float:left;
}

.w-facebook-moditt h5{
    padding-top: 10px !important;
    font-size:12px !important;
}
.footer-contents{

    width:85% !important;
    height:100%;
}

.footer-text{
    width:100%;
}

.footer-tablet{
    display: block;
}

.container-fluid{
    width: 100% !important;
    align-content: center;
  }  

.facebook-moditt-mobile {
    display: block;
}
  
.news-img-mobile{
    display: block;
}

.comment2{
    display: block;
}


/* display : none */
.indicate, .mobile-copyright,.menu, .desktop, .moditt-img, .news-link-desktop
,.footer-desktop,.news-img-tablet,.app-tablet, .section1-base,.facebook-moditt-base, 
.comment21,.news-img-base, .team-line-top-desktop{
    display:none;
}
}