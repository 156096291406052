@import url("../base.css");

.section6{
    width:100%;
    height:1820px;
}

.section6_top{
    width:100%;
    height:700px;
    background-color: #fafafa;
}

.three-img{
    width: 33%;
    float:left;
    padding-top:120px;
    height:260px;
}

.moditt-work{
    width:380px;
    height:260px;
    float:left;
}

.moditt-img{
    width:790px;
    height:360px;
    float:right;
    padding-right: 30px;
    padding-top:250px;
}

.section6_bottom{
    width:100%;
    height:1120px;
    background-color: #22262d;
}

.w-contents-left2{    
    height:20%;
    float:left;
}

.moditt-work-text{
    width: 380px;
    height: 50px;
    margin: 0;
    padding-top:260px;
}

.w-contents-text{
    /* width:85%;
    height:520px; */
    padding-top:120px;
    word-break: keep-all;
    
  }

  .w-contents-text .team-line-top{
    width:26px;
    height:5px;
    float:left;
    background-color:#ffffff;
    float:left;


  }
  


.moditt-work-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size:20px;
    line-height: 1;
    font-weight: 900;
    color:#30343b;
    padding-top:40px;
}


.moditt-work-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1.67;
    font-weight: 400;
    color:#30343b;
    padding-top:20px;
}

.w-contents-text{
    /* width:85%;
    height:520px; */
    padding-top:120px;
    word-break: keep-all;
    
  }

  .w-contents-text .team-line{
    width:26px;
    height:5px;
    float:left;
    background-color:#ffffff;
    margin-top:120px;
    float:left;


  }
  
  .w-contents-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size:35px;
    line-height: 1;
    color:#ffffff;
    padding-top:20px;
    font-weight:900;
  
  }
  .w-contents-text h2{
    font-family:'NanumSquare', sans-serif;
    font-size:35px;
    line-height: 1.29;
    font-weight: 900;
    color:#ffffff;
    padding-top:140px;
  
  }
  .w-contents-text h3{
    font-family:'NanumSquare', sans-serif;
     font-weight: 400;
    font-size:16px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:40px;
    color:#000000;
    width: 92%;
    float: left;
    font-weight: 300;
  
  }
  .box{
      width:520px;
      float: left;
      height: 179px;
      text-align: left;
      padding-top:20px;
  }

  .box2{
    width:520px;
    float: right;
    height: 179px;
    text-align: right;
    padding-top:20px;
    padding-right:30px;
}

  .box h1 {
    font-family:'NanumSquare', sans-serif;
    font-size:25px;
    line-height: 1;
    padding-top:20px;
    color:#ffffff;
    font-weight: 600;
  }


  .ham {
    font-family:'NanumSquare', sans-serif;
    font-weight: 900;
    font-size:25px;
    line-height: 1;
    padding-top:20px;
    color:#ffffff;
    text-align: right;
  }


  /* .box h1 {
    font-family:'NanumSquare', sans-serif;
    font-weight: 900;
    font-size:25px;
    line-height: 1;
    padding-top:20px;
    color:#ffffff;
  } */

  .box h2 {
    font-family:'NanumSquare', sans-serif;
    font-weight: 400;
    font-size:17px;
    line-height: 1.76;
    padding-top:19px;
    opacity: 0.5;
    color:#ffffff;
  }
  
  .ham-h2{
    font-family:'NanumSquare', sans-serif;
    font-weight: 400;
    font-size:17px;
    line-height: 1.76;
    padding-top:19px;
    opacity: 0.5;
    color:#ffffff;
    text-align: right;
  }
  .comment{
      width:120px;
      height:55px;
  }

  .comment2{
    width:120px;
    height:55px;
}

.comment21{
  width:120px;
  height:55px;
}
  .interview{
      width:100%;
      float:left;
  }

