@import url("../base.css");

.footer{
    width:100%;
    height:200px;
    background-color:#22262d;
}
.footer-contents{

    width:72%;
    height:100%;
}

.footer-text{
    width:100%;
}

.copyright{
    width:53%;
    float:left;
}

.user{
    width:47%;
    float:left;
    
}

.user h5{
    float:left;
    padding-left:20px;
    opacity:1;
    font-weight: bold;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    line-height:1.67;
    cursor: pointer;
}

h6{
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:14px;
    opacity: 0.5;
    padding-top:60px;
    
}

.adress{
    width:100%;
    padding-top:20px;
}

.adress h5{
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    opacity: 0.5;
    line-height:1.67;
    padding-top:20px;
}

h5{
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    opacity: 0.5;
    line-height:1.67;
}

hr{
    opacity: 0.2;
    width:100%;
    background-color: #ffffff;
}


.opacity{
    opacity: 0.5 !important;
    line-height: 1.67 !important; 
    font-weight: 500 !important;
}



/* display : none */
.mobile-copyright {
    display:none;
}


.w-facebook-moditt{
    width:50%;
    height:100%;
    float:left;
}

.w-facebook-moditt h1{
    padding: 60px 0px 0px 0px;
    font-family:'NanumSquare', sans-serif;
    font-size:20px;
    line-height: 1;
    color:#ffffff;
    font-weight: 600;
}

.w-facebook-moditt h2{
    padding-top: 20px;
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1;
    opacity: 0.7;
    font-weight: 300;
    color:#ffffff;
}

.w-facebook-moditt h3{
    padding-top: 10px;
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1;
    opacity: 0.7;
    font-weight: 300;
    color:#ffffff;
}
.w-facebook-moditt h4{
    padding-top: 100px;
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1;
    opacity: 0.7;
    font-weight: 300;
    color:#ffffff;
}

.w-facebook-moditt h5{
    padding-top: 10px;
    font-family:'NanumSquare', sans-serif;
    font-size:15px;
    line-height: 1;
    opacity: 0.7;
    font-weight: 300;
    color:#ffffff;
}

