  /* Device = Tablets, Ipads , Screen = 768px to 1023px */
@media (min-width: 768px) and (max-width: 1367px) {  
    .wrap{    
        width:100%;
        height:100%;
        padding-bottom: 64px !important;
    }
    .munse_app {
        display: none;
    }
    
    .solid {
        border:1px 
        solid #dddddd;
        border-top-style: none;
        border-bottom-style: none;
        border-left-style:none;
        border-right-style:none;
      }

      .mobile-slidebar{
        display: block;
    }

    .mobile-slidebar.shown{
        display: none;
    }


    .size{
        width:100% !important; 
      }

    .section-contents{
        width:90% !important;
        height:100%;
        align-content: center;
    }

    .moditt_logo{         
        float:left;
        width:120px !important;
        height:30px !important;
        margin:0 auto;
        padding:0 auto;
        text-align: center;
        padding: 10px 0px 10px 20px !important;
    }

    .moditt_logo.shown{ 
        display: none;
        float:left;
        width:120px !important;
        height:30px !important;
        margin:0 auto;
        padding:0 auto;
        text-align: center;
        padding: 10px 0px 10px 20px !important;
    }

    .moditt_logo_fixed{    
        float:left;
        width:120px !important;
        height:30px !important;
        margin:0 auto;
        padding:0 auto;
        text-align: center;
        padding: 10px 0px 10px 20px !important;
        z-index: 999999 !important;
        position: absolute;
    }
    .header-fixed.shown{
        height:49px !important;
    }

    .nav-menu{
        width:95%;
        height:49px;  
        display: none;    
    }

    .menu-bar{
        width:25px;
        height:25px;
        display:block;
        float:right;
        padding:12.5px;
    }
    .navbar{
        width:100% !important;
        height:100%;
        margin:0 auto;
        font-family: 'NanumSquare', sans-serif;
        font-weight:700; 
        float:right;
        text-align:center;
    }

    .all_header{
        height:360px;
    }

    .header_title{
        top:10%;
    }

    .change_life_style{
        width: 688px;
        height: 40px;
        font-family: 'NanumSquare', sans-serif;
        font-size: 40px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding-top:120px;
    }
    /* .menu{
        height:100%;
        margin:0 auto;
        font-family: 'Noto Serif KR', sans-serif;
        font-weight:700; 
        float:right;
        text-align:center;
        font-size:14px;
    } */
/*     
    .menu li{
        float:left;
        list-style:none;
        line-height: 100%;
        padding: 25px 30px 25px 0px;
    } */
    
    .header-nav{
        float:right;
        font-size:16px;
        color: #000000;
        margin:0 auto;
        padding: 0.5%;
        font-family: 'NanumSquare', sans-serif;
        font-weight:700; 
        padding-right:2%;
    }

.section1{
    width:100% !important;
    height:417px !important;
    /* background-repeat: no-repeat !important;
    background-position:center !important;      */
    /* background-position : center !important;
    background-repeat : no-repeat !important; */
    }
    
.contents{
    width:100% !important;
    height:90% !important;
}

.contents-left{
    width:100% !important;
    height:25% !important;
    float:left;
    text-align: center !important;
    vertical-align: middle;
    display:table-cell;
    padding: 0 auto;
    margin: 0 auto;
  }

.contents-text{
    padding-top:75px;
    float:left;
}
.contents-right{
    width: 100%!important;
    height:65% !important;
    float:left ;
    /* text-align: center !important; */
    /* vertical-align: middle; */
    display:table-cell;
    padding: 0 auto;
    margin: 0 auto;
  }
.contents-text-right{
    padding-top:40px;
}

.contents-text-right h2{
    font-family: 'NanumSquare', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;    
    letter-spacing: normal;
    color: #30343b;
}

.contents-text-right h3{
    padding-top:40px;
    font-family: 'NanumSquare', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87 !important;
    letter-spacing: normal;
    color: #30343b;
}

.contents-text-no-title h3{
    font-size:15px !important;
    line-height: 1.87 !important;
}
.contents-contents{
    text-align:center !important; 
    color:#000000;
    padding-top:0px;
  
  }

.contents-text{
    text-align:center !important;
}

.contents-text-top{
    width:100% !important;
    height:45% !important;
    padding-top:100px !important;
    margin: 0px 0px !important;
    word-break: keep-all;
  }

.top-app, .indicate{
    display:none;
}
  

.sectionimg{
    width:inherit;
    max-width:100%;
    width:470.8px;
    height:480px;
    padding:50px 100px !important;

}

.top-text{
    font-size:32px;
    Line-height:1.38;
    color:#ffffff;
    text-align: center;

}

.top-title{
    font-family: 'NanumSquare', sans-serif;
    font-weight:600 !important; 
  font-size:32px;
  Line-height:1.38;
  color:#ffffff;
  text-align:center;
}

.top-contetns{
    font-family: 'NanumSquare', sans-serif;
    font-weight:400 !important; 
    font-size:14px !important;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:30px;
    color:#ffffff;
    text-align:center;
}
.indicate-width{
    width:100%;
    float:left;
    text-align: center;
}
.indicate{
    width:12px;
    height:12px;
    text-align: center;
}

/* section2*/
.section2{
    width:100% !important;
    height:211.7px !important;
    max-width:1367px !important;
}
.sectionimg1{
    width:inherit;
    max-width:100%;
    width: 470.8px;
    height:480px;
    padding:0px 0px;
}
.contents-text h1{
    font-family:'NanumSquare', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-bottom: 75px;
   
  
  }
  .contents-text h2{
    font-family:'NanumSquare', sans-serif;
    font-weight: 900;
    font-weight:600 !important; 
    font-size:28px;
    Line-height:1.38;
    font-weight: 300;
    color:#000000;
    text-align: center;
  
  }
  .contents-text h3{
    font-family:'NanumSquare', sans-serif;
    font-weight:600 !important; 
    font-size:14px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:50px;
    color:#000000;
    text-align: center;
    width:100%;
  }
  .section3{
    width:100%;
    height:408px !important;
    background-color:#fafafa;
}

.contents-text-no-title{
    padding-top:40px;
    font-family: 'NanumSquare', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #30343b;
    height:100%;
}   

.section4{
    width:100%;
    height:1048px !important;
}
.app-tablet{
    display:block;
}
.app-tablet-section{
    width:100%;
    height:25%;
    float:left;
    padding-top:75px;   
}

.app-icon{
    width:80px !important;
    height:80px !important;
}

.contents-app{
    padding:0px !important;
    width:  0px !important;
}

.app-description {
    padding-left:120px;
    width:83% !important;
}
.app-description h1{
    font-family: 'NanumSquare', sans-serif;
    font-size: 20px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:0px !important;
    float: left;
}

.google-apple h1{
    font-size:15px !important;
    float:left;
    padding: 16.5px 0px 0px 12px !important;
    width:120px !important;
}
.web {
    padding-top:0 !important;
}

.app-description h2{
    opacity: 0.5;
    font-family:'NanumSquare', sans-serif;
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87 !important;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:40px !important;
    word-break: break-all !important;
}


.google-apple{
    padding-top:0 !important;
    cursor: pointer;
}


.app{
    padding-top:40px !important;
    padding-bottom:0px !important;
    width:180px !important;
    padding-right:15px;
}
.go-to-web h3{
    padding-top: 0px !important;
    padding-left:20px;
    cursor: pointer;
}

.section5{
    width:100%;
    height:678px !important;
}

.w-contents-left{
    width:100% !important;
    height: 48% !important;
}

.w-contents-text h1{
    font-size: 34px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
}

.w-contents-text h2{
    font-size: 28px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:91px !important;
}

.w-contents-text{
    padding-top:75px !important;
}

.w-contents-text .team-line {
    margin-top:75px !important;
    width:20.8px !important;
    height:4px !important;
}

.w-contents-right{
    width:100% !important;
    height:50% !important;
}

.w-contents-text-right{
    padding-top:75px !important;
}

.w-contents-text-right h2{
    font-family:'NanumSquare', sans-serif;
    font-size: 25px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;  
}

.w-contents-text-right h3{
    font-family: 'NanumSquare', sans-serif;
  font-size: 15px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.87;
  letter-spacing: normal;
  color: #ffffff;
  word-break: break-all;
}



.sectionimg4{
    width:inherit;
    max-width:100%;
    width: 470.8px !important;
    height:480px !important;
    padding:0px 0px !important; 

}
.section6 {
    height:3246px !important;
}
.section6_top{
    width:100% !important;
    height:2013px !important;
}




/* .section6-tablet{
    position:absolute;
    float: center;
    align-content: center
} */

.three-img{
    width: 100% !important;
    float: left;
    padding-top: 75px !important;
    height: 471px !important;
    padding-bottom:90px;
}

.moditt-work{
    width:100% !important;
    height:471px !important;
}

.moditt-work-text{
    float: left;
    padding-top:40px !important;
    width:100% !important;
}

.moditt-work-text h1{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-top:0 !important;
}

.moditt-work-text h2{
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #30343b;
    padding-top: 20px !important;
}

.moditt-img-tablet{
    width:100% !important;
    height: 350px !important;
    display: block;
}

.team-line-top{
    width:20.8px !important;
    height: 4px !important;
}

.team-line-top-desktop{
    display: block;
    width:20.8px !important;
    height: 4px !important;
    background-color: #22262d;
    float: left;
}


.section6_bottom{
    height:1233px !important;
}

.w-contents-left2{
    height:127px !important;
    width:100%;
}

.box{
    padding-top:60px !important;
    width: 100% !important;
    height:157px !important;
}

.box2{
    padding-top:60px !important;
    width: 100% !important;
    height:157px !important;
    text-align: left !important;
    padding-right:0px !important;
}
.ham {
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    padding-top:66px !important;
    color: #ffffff; 
    text-align: left !important;
  }


.ham-h2{
    font-family:'NanumSquare', sans-serif;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 1.87 !important; 
    padding-top:19px;
    opacity: 0.5;
    color:#ffffff;
    text-align: left !important;
  }

.box h1{
    font-size: 20px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff; 
}

.box h2{
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #ffffff;
}

.interview-right{
    float: left !important;
}

.comment{
    width:100px !important;
    height:45.8px !important;
}
.contents-top{
    width:100%;
    height:62%;
}

.contents-mus{
    width: 57%;
    height:100%;
}
.contents-mus-text{
    text-align: center;
    padding-top:80px;
}

h4{
    font-family: 'NanumSquare', sans-serif;
        font-weight:500 !important; 
    font-size: 16px !important;
    line-height: 1.44;
    text-align: center;
    color: #000000;
    padding: 20px 20px;
}

.appicon{
    width:100px;
    height:100px;
    
}

.section7{
    height: 438px !important;
}

.b-contents-text h1{
    font-size: 28px !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
}

.b-contents-text-no-title{
    padding-top:75px !important;
    width:100% !important;
    font-weight:900 !important;
}

.b-contents-text-no-title h3{
    width:100% !important;
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.87 !important;
    letter-spacing: normal;
    color: #30343b;
}

.join{
    margin-top:40px !important;
}

.section8{
    height: 1537px !important;
}

.news{
    width:688px !important;
    height: 471px !important;
}

.news-img{
    width:100% !important;
    padding-top:0px !important;
    height: 676px !important;
}

.news-text{
    width:100% !important;
}

.news-text h1{
    float:left;
    font-size: 20px !important;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #30343b;
    padding-top:40px !important;
}
.news-link-tablet{
    display: block;
    float:left;
} 


.news-text h3{
    padding-top:44px !important;
    padding-left:20px !important;
}

.news-web{
    padding-top:44px !important;
}

.news-text h2{
    float:left;
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #30343b;
    width: 100%;
}

.section9{
    height:140px !important;
}
.facebook-moditt{
    width:240px !important
}

.facebook-moditt h1 {
    font-size:20px !important;
    font-weight: 600 !important;
}

.facebook{
    margin-left:267px !important;
}

.facebook h1{
    font-size: 15px !important;
}
.contents-app-down{
    width:57%;
    height:100%;
    text-align:center;
    margin:0 auto;
    padding:0 auto;
}

.contents-app-text{
    text-align: center;
    
}
.appstore-bottom, .googleplay-bottom{    
    display: none;
}

.google{
    height:50px !important;

}

.google-maum{
    width: 375px;
    height: 100%;
    float: left;
    border-radius: 0.5em;
    border: 1px solid #ffffff;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;

}

.Media-play-icon{
    padding:14px 0px 0px 14.5px;
}

.appdownload{
    text-align:center;
    width:200px;
    height:46px;
    padding-top:30px;
    display:block !important;
    cursor: pointer;
}

.contents-bottom{
    width:100%;
    height:38%;
    text-align:center;
}
.footer{
    width:100%;
    height:280px !important;
}

.w-facebook-moditt{
    width:100% !important;
    height: 50% !important;
}

.w-facebook-moditt h4{
    padding-top:40px !important;
    float:left;
}

.w-facebook-moditt h5{
    padding-top: 10px !important;
}
.footer-contents{

    width:85% !important;
    height:100%;
}

.footer-text{
    width:100%;
}

.footer-tablet{
    display: block;
}

.container-fluid{
    width: 100% !important;
    align-content: center;
  }  


.header{
    width: 100%;
    height: 130px;
    font-family: 'NanumSquare', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    z-index: 0;
    position: fixed;
    -webkit-transition: top 0.2s ease-in-out;
    transition: top 0.2s ease-in-out;
}

.header.shown{
    display: none;
    width: 100%;
    height: 130px;
    font-family:'NanumSquare', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    z-index: 0;
    position: fixed;
    -webkit-transition: top 0.2s ease-in-out;
    transition: top 0.2s ease-in-out;
}


  .header-fixed{  
    visibility: hidden;  
    width:100%;
    height: 100px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    background-color: #ffffff;
    z-index :1;
    position: fixed;
    border-bottom: .5px solid #eee;
    /* text-align: center;
    position: fixed !important;
    display:flex;
    z-index :99999; */
    
}


.header-fixed.shown{  
    visibility: visible;   
    width:100%;
    height: 100px;
    font-family: 'NanumSquare', sans-serif;
    font-weight:500; 
    font-size:20px;
    background-color: #ffffff;
    z-index :1;
    position: fixed;
    border-bottom: .5px solid #eee;
    /* text-align: center;
    position: fixed !important;
    display:flex;
    z-index :99999; */
    
}

.news-img-tablet{
    display: block;

}

.comment2{
    display: block;
    width: 100px !important;
    height: 45.8px !important;
    float: left;

}


/* display : none */
.indicate, .mobile-copyright,.menu, .menu-fixed,.desktop, .moditt-img, .news-link-desktop
,.footer-desktop, .team-line-top-career, .news-img-base, .section1-mobile,.section-mobile,.facebook-moditt-mobile,.comment21{
    display:none;
}
}