@import url("../base.css");

.section4{
    width:100%;
    height:882px;
    background-color:#22262d;
   display: block;
}

.munse_app {
    display: none;
}

.contents-top{
    width:100%;
    height:50%;
}

.contents-bottom{
    width:100%;
    height:50%;
}

.contents-app{
    width:30%;
    padding:120px 0 0 0;
    float:left;
}


.app-description-bottom {
    width:70%;
    height:100%;
    float:left;
    
}
.app-description-bottom h1{
    font-size:25px;
    line-height: 1;
    font-weight:400;
    font-family:'NanumSquare', sans-serif;
    color:#ffffff;
    /* padding-top:80px; */
}

.app-description-bottom h2{
    font-size:17px;
    line-height: 1.76;
    font-weight: 400;
    opacity: 0.5;
    font-family:'NanumSquare', sans-serif;
    color: #ffffff;
    padding-top:20px;
}

.app-description-bottom h3{
    font-size:14px;
    line-height: 1;
    font-weight: 400;
    font-family:'NanumSquare', sans-serif;
    color:#24c1b0;
    padding-top:30px;
    text-decoration: underline;
    width: 106px;
    float: left;
    
}


.app-description {
    width:70%;
    height:100%;
    float:left;
    
}

.app-description h1{
    font-size:25px;
    line-height: 1;   
    font-family:'NanumSquare', sans-serif;
    color:#ffffff;
    padding-top:120px;
    font-weight:400;
}



.app-description h2{
    font-size:17px;
    line-height: 1.76;
    font-weight: 400;
    opacity: 0.5;
    font-family:'NanumSquare', sans-serif;
    color: #ffffff;
    padding-top:20px;
}

.app-description h3{
    font-size:14px;
    line-height: 1;
    font-weight: 400;
    font-family:'NanumSquare', sans-serif;
    color:#24c1b0;
    padding-top:30px;
    text-decoration: underline;
    width: 106px;
    float: left;
    
}
.go-to-web{
    width:100%;
}
.web{
    width:16px;
    height:16px;
    padding-left:7px;
    float:left;
    padding-top:30px;
  }
  
  

.google-apple{
    width:100%;
    height:50px;
    padding-top:52px;
}
.google-apple h1{
    font-weight: 400;
    font-family:'NanumSquare', sans-serif;
    line-height: 1;
    color: #ffffff;
    font-size:15px;
    padding-top:0;
    width:80%;
    padding: 17.5px 0px 17.5px 46.5px;

}

.google {
    width:180px;    
    height:100%;
    float:left;
    border-radius:0.5em;
    border: 1px solid #ffffff;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
}

.apple {
    width:180px;
    height:100%;    
    float:left;
    border-radius:0.5em;
    border: 1px solid #ffffff;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
}



.contents-app-bottom{
    width:30%;
    /* padding:80px 0 0 0; */
    float:left;
}

.app-icon {
    width: 120px;
    height: 120px;
    float:left;   
}

.app{
    width: 50%;
    height: 100%;
    float:left;
    padding-top: 50px;
    padding-bottom: 80px;
}